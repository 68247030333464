import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TokenListingService from "../../services/tokenlisting/TokenListingService";

class TokenListing extends React.Component {
    constructor(props) {
        super(props);

        this.service = new TokenListingService();

        this.state = {
            listing: {
                name: "",
                slug: "",
                email: "",
                website: "",
                contract_address: "",
                contact: "",
                phone_number: "",
                description: ""
            },
            isError: {
                name: "",
                slug: "",
                email: "",
                website: "",
                contract_address: "",
                contact: "",
                phone_number: "",
                description: ""
            }
        };
    }

    onSubmit = e => {
        e.preventDefault();

        var params = this.state.listing;

        this.service.tokenListingRequest(params).then(res => {
            if (res.success) {
                e.target.reset();

                this.resetAll();

                toast.success(res.message);
            } else {
                this.showErrorMsg(res.message);
            }
        }).catch((err) => {
            toast.error("Something went to problem, Please try again!.");
        });
    }

    resetAll() {
        this.setState({
            listing: {
                ...this.state.listing,
                name: "",
                slug: "",
                email: "",
                website: "",
                contract_address: "",
                contact: "",
                phone_number: "",
                description: ""
            },
            isError: {
                ...this.state.isError,
                name: "",
                slug: "",
                email: "",
                website: "",
                contract_address: "",
                contact: "",
                phone_number: "",
                description: ""
            }
        });
    }

    showErrorMsg(errors) {
        var isErrors = this.state.isError;

        for (var i in isErrors) {
            isErrors[i] = errors[i] ? errors[i][0] : "";
        }

        this.setState({isErrors});
    }

    formValChange = e => {
        e.preventDefault();
        const { name, value } = e.target;

        this.setState({
            listing: {
                ...this.state.listing,
                [name]: value
            },
            isError: {
                ...this.state.isError,
                [name]: ''
            }
        });

    }

    render() {
        const { isError } = this.state;

        return (
            <div>
                <article className="gridparentbox tokenlistbg">
                    <section className="innerpagecontent tokenlist">
                        <div className="container sitecontainer">
                            <div className="topcontentbox">
                                <h3 className="heading-title pb-2">Token Listing</h3>
                            </div>
                        </div>
                    </section>
                    <section className="token-applybg">
                        <div className="container">
                            <div className="panelcontentbox contentbox">
                                <form className="siteformbox" onSubmit={this.onSubmit} noValidate>
                                    <div className="form-group">
                                        <label>
                                            Email<span className="text-danger"> *</span>
                                        </label>
                                        <input type="text" name="email" className={isError.email.length > 0 ? "is-invalid form-control" : "form-control"} onChange={this.formValChange} />
                                        { isError.email.length > 0 && (
                                            <span className="invalid-feedback">{isError.email}</span>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>
                                            Name<span className="text-danger"> *</span>
                                        </label>
                                        <input type="text" name="name" className={isError.name.length > 0 ? "is-invalid form-control" : "form-control"} onChange={this.formValChange} />
                                        { isError.name.length > 0 && (
                                            <span className="invalid-feedback">{isError.name}</span>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>
                                            Project Website<span className="text-danger"> *</span>
                                        </label>
                                        <input type="text" name="website" className={isError.website.length > 0 ? "is-invalid form-control" : "form-control"} onChange={this.formValChange} />

                                        { isError.website.length > 0 && (
                                            <span className="invalid-feedback">{isError.website}</span>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label> Symbol <span className="text-danger"> *</span> </label>
                                        <input type="text" name="slug" className={isError.slug.length > 0 ? "is-invalid form-control" : "form-control"} onChange={this.formValChange} />

                                        { isError.slug.length > 0 && (
                                            <span className="invalid-feedback">{isError.slug}</span>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label> Contract Address <span className="text-danger"> *</span> </label>
                                        <input type="text" name="contract_address" className={isError.contract_address.length > 0 ? "is-invalid form-control" : "form-control"} onChange={this.formValChange} />

                                        { isError.contract_address.length > 0 && (
                                            <span className="invalid-feedback">{isError.contract_address}</span>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label> Name of Primary Contact <span className="text-danger"> *</span> </label>
                                        <input type="text" name="contact" className={isError.contact.length > 0 ? "is-invalid form-control form-control-lg" : "form-control form-control-lg"} onChange={this.formValChange} />

                                        { isError.contact.length > 0 && (
                                            <span className="invalid-feedback">{isError.contact}</span>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label> Phone Number of Primary Contact (please add country code) <span className="text-danger">*</span> </label>
                                        <input type="text" name="phone_number" className={isError.phone_number.length > 0 ? "is-invalid form-control form-control-lg" : "form-control form-control-lg"} onChange={this.formValChange} />

                                        { isError.phone_number.length > 0 && (
                                            <span className="invalid-feedback">{isError.phone_number}</span>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label> Comments<span className="text-danger"> *</span></label>
                                        <textarea rows="2" name="description" className={isError.description.length > 0 ? "is-invalid form-control form-control-lg" : "form-control form-control-lg"} onChange={this.formValChange}></textarea>
                                        { isError.description.length > 0 && (
                                            <span className="invalid-feedback">{isError.description}</span>
                                        )}
                                    </div>

                                    <div className="text-center mt-3">
                                        <input type="submit" value="Send" name="send" className="btn sitebtn" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </article>

                <ToastContainer autoClose={5000} />
            </div>
        );
    }
}

export default TokenListing;
