import React from "react";

import logo from './../../assets/images/cmg.svg';

class ComingSoon extends React.Component {
    render() {
        return (
            <div className="comingsoonbg">
                <div className="cs">
                    <div className="container">
                        <img src={logo} className="cm-logo" />
                    </div>
                </div>
            </div>
        );
    }
}

export default ComingSoon;
