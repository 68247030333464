import React from "react";
import ReactLoading from 'react-loading';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ContactService from "../../services/contact/ContactService";

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.service = new ContactService();

        this.state = {
            contact: {
                name: "",
                email: "",
                subject: "",
                message: ""
            },
            isError: {
                name: "",
                email: "",
                subject: "",
                message: ""
            }
        };
    }

    onSubmit = e => {
        e.preventDefault();

        var params = this.state.contact;

        this.service.contactRequest(params).then(res => {
            if (res.success) {
                e.target.reset();

                this.resetAll();

                toast.success(res.message);
            } else {
                this.showErrorMsg(res.message);
            }
        }).catch((err) => {
            toast.error("Something went to problem, Please try again!.");
        });
    }

    resetAll() {
        this.setState({
            contact: {
                ...this.state.contact,
                name: "",
                email: "",
                subject: "",
                message: ""
            },
            isError: {
                ...this.state.isError,
                name: "",
                email: "",
                subject: "",
                message: ""
            }
        });
    }

    showErrorMsg(errors) {
        var isErrors = this.state.isError;

        for (var i in isErrors) {
            isErrors[i] = errors[i] ? errors[i][0] : "";
        }

        this.setState({isErrors});
    }

    formValChange = e => {
        e.preventDefault();
        const { name, value } = e.target;

        this.setState({
            contact: {
                ...this.state.contact,
                [name]: value
            },
            isError: {
                ...this.state.isError,
                [name]: ''
            }
        });

    }

    render() {
        const { isError } = this.state;

        return (
            <article className="gridparentbox">
                <div className="container sitecontainer ">
                    <h3 className="heading-title pb-2 text-center">Contact US</h3>
                    <div className='panelcontentbox contentbox contactbg'>
                        <form className="siteformbox" onSubmit={this.onSubmit} noValidate>
                            <div className="form-group">
                                <label>Name<span className="text-danger"> *</span></label>
                                <input type="text" name="name" className={isError.name.length > 0 ? "is-invalid form-control" : "form-control"} onChange={this.formValChange} />

                                { isError.name.length > 0 && (
                                    <span className="invalid-feedback">{isError.name}</span>
                                )}
                            </div>
                            <div className="form-group">
                                <label>Email<span className="text-danger"> *</span></label>
                                <input type="text" name="email" className={isError.email.length > 0 ? "is-invalid form-control" : "form-control"} onChange={this.formValChange} />
                                { isError.email.length > 0 && (
                                    <span className="invalid-feedback">{isError.email}</span>
                                )}
                            </div>
                            <div className="form-group">
                                <label>Subject<span className="text-danger"> *</span></label>
                                <input type="text" name="subject" className={isError.subject.length > 0 ? "is-invalid form-control" : "form-control"} onChange={this.formValChange} />

                                { isError.subject.length > 0 && (
                                    <span className="invalid-feedback">{isError.subject}</span>
                                )}
                            </div>
                            <div className="form-group">
                                <label>Message<span className="text-danger"> *</span></label>
                                <textarea rows="3" name="message" className={isError.message.length > 0 ? "is-invalid form-control form-control-lg" : "form-control form-control-lg"} onChange={this.formValChange}></textarea>

                                { isError.message.length > 0 && (
                                    <span className="invalid-feedback">{isError.message}</span>
                                )}
                            </div>
                            <div className='text-center'>
                                <input type="submit" value="Send" name="send" className="sitebtn" />
                            </div>
                        </form>
                    </div>
                </div >

                <ToastContainer autoClose={5000} />
            </article>
        );
    }
}

export default Contact;
