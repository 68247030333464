import React from "react";
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import Modal from 'react-bootstrap/Modal';

// import TokenListComponent from '../../components/token/TokenListComponent';
import TokenListWithBalanceComponent from '../../components/token/TokenListWithBalanceComponent';
import TokenComponent from '../../components/token/TokenComponent';
import ConnectWallet from '../../components/connectwallet/ConnectWallet';

import ExchangeService from "../../services/exchange/ExchangeService";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import downarrow from './../../assets/images/downarrow.svg';
import nodata from './../../assets/images/nodata.svg';
import eth from './../../assets/images/color/eth.svg';

import eventBus from "../../events/EventBus";
import PaymentUtil from "../../hooks/PaymentUtil";
import ExchangeUtil from "../../hooks/ExchangeUtil";

class Exchange extends React.Component {
    constructor(props) {
        super(props);

        this.exchangeUtilRef = React.createRef();
        this.paymentUtilRef = React.createRef();

        this.service = new ExchangeService();

        this.state = {
            show: false,
            tokens: [],
            tokensBalance: [],
            isBalLoading: false,
            isLiqLoading: false,
            isEnableLiqView: true,
            liquidityData: null,
            liqAlertShow: false,
            approveToken: null,
            inputLiqCoinShow: false,
            outputLiqCoinShow: false,
            inputCoinShow: false,
            outputCoinShow: false,
            filteredTxtInput: "",
            filteredTxtOutput: "",
            filteredTxtLiqtyInput: "",
            filteredTxtLiqtyOutput: "",
            filteredTxtLiqtyInputContract: process.env.REACT_APP_ETH,
            filteredTxtLiqtyOutputContract: "",
            filteredInputBalance: localStorage.getItem("balance") ? localStorage.getItem("balance") : 0,
            filteredOutputBalance: 0,
            inputAmt: 0,
            outputAmt: 0,
            total: 0,
            isShowInputAlert: false,
            isShowOutputAlert: false,
            isShowInputAllowence: false,
            isShowOutputAllowence: false,
            isShowAddLiq: false,
            approveFilterType: "",
            isAddLiqBtn: false,
            liquidityList: [],
            ispairloading: false,
            isShowLiqPair: false,
            filteredPairData: {},
            liqInBal: 0,
            liqOutBal: 0,
            pairData: {},
            poolShare: 0,
            receiveTokens: 0,
            rmLiqAllowence: false,
            isFirstLiq: false,
            slippagePerc: 0,
            isPayPopup: false,
            isPaid: false,
            payAmt: 0,
            payLoading: false,
            removeLiqLoading: false,
            removeApproveLiqLoading: false
        };

        this.showModal = this.showModal.bind(this);

        this.showLiqInputCoin = this.showLiqInputCoin.bind(this);
        this.showLiqOutputCoin = this.showLiqOutputCoin.bind(this);

        this.showInputCoin = this.showInputCoin.bind(this);
        this.showOutputCoin = this.showOutputCoin.bind(this);

        this.showHideLiqPair = this.showHideLiqPair.bind(this);

        this.amtCalInputEvent = this.amtCalInputEvent.bind(this);
        this.amtCalOutputEvent = this.amtCalOutputEvent.bind(this);

        this.filterPercentage = this.filterPercentage.bind(this);
    }

    componentDidMount() {
        this.getTokensBalance();
        this.checkPay();

        if (this.service.address()) {
            this.getTokensBalance();
            this.getLiquidityList();
        }

        eventBus.on("connectWallet", (data) => {
            this.setState({filteredTxtLiqtyInput: localStorage.getItem("token")});

            if (data.status && this.service.address()) {
                this.checkPay();
                this.getTokensBalance();
                this.getLiquidityList();
            }
        });

        eventBus.on("disconnectWallet", (data) => {
            this.resetAll();
            localStorage.clear();
            this.service.address();

            this.getTokensBalance();
        });

        eventBus.on("networkchange", (data) => {
            this.checkPay();
            this.getTokensBalance();
            this.getLiquidityList();
        });
    }

    resetAll() {
        this.setState({
            show: false,
            tokens: [],
            tokensBalance: [],
            isBalLoading: false,
            isLiqLoading: false,
            liquidityData: null,
            liqAlertShow: false,
            approveToken: null,
            inputLiqCoinShow: false,
            outputLiqCoinShow: false,
            inputCoinShow: false,
            outputCoinShow: false,
            filteredTxtInput: "",
            filteredTxtOutput: "",
            filteredTxtLiqtyInput: "",
            filteredTxtLiqtyOutput: "",
            filteredTxtLiqtyInputContract: process.env.REACT_APP_ETH,
            filteredTxtLiqtyOutputContract: "",
            filteredInputBalance: localStorage.getItem("balance") ? localStorage.getItem("balance") : 0,
            filteredOutputBalance: 0,
            inputAmt: 0,
            outputAmt: 0,
            total: 0,
            isShowInputAlert: false,
            isShowOutputAlert: false,
            isShowInputAllowence: false,
            isShowOutputAllowence: false,
            isShowAddLiq: false,
            approveFilterType: "",
            isAddLiqBtn: false,
            liquidityList: [],
            ispairloading: false,
            isShowLiqPair: false,
            filteredPairData: {},
            liqInBal: 0,
            liqOutBal: 0,
            pairData: {},
            poolShare: 0,
            receiveTokens: 0,
            payLoading: false
        });
    }

    checkPay() {
        var params = {
            address: this.service.address(),
            network: this.service.network()
        };

        this.service.getPaymentStatus(params).then(res => {
            if (res.success) {
                this.setState({
                    isPaid: (res.data.pay_status == "true") ? true : false,
                    payAmt: res.data.pay_amount,
                    isPayPopup: (res.data.pay_status == "true") ? false : true
                });
            }
        });
    }

    payPopupAction = () => {
        this.setState({ isPayPopup: !this.state.isPayPopup });
    }

    getToken() {
        this.setState({isLiqLoading: true});

        this.service.getTokens(this.service.network()).then(res => {
            if (res.success) {
                this.setState({tokens: res.data, isLiqLoading: false});
            }
        });
    }

    getTokensBalance() {
        var params = {
            address: this.service.address(),
            avail: this.state.filteredInputBalance,
            network: this.service.network()
        };

        this.setState({ isBalLoading : true, isLiqLoading: true});

        this.service.getAllTokenBalance(params).then(res => {
            if (res.success) {
                this.setState({tokensBalance: res.data, isBalLoading: false, isLiqLoading: false}, () => {
                    this.updateBal();
                });
            }
        });
    }

    updateBal() {
        let inputTxt = "";

        if (this.state.tokensBalance.length > 0) {
            inputTxt = this.state.tokensBalance[0]['slug'];
        }

        if (inputTxt) {
            let input = this.filteredBalance(inputTxt);
            this.setState({filteredTxtInput: inputTxt, filteredInputBalance: input });
        }

        if (this.state.filteredTxtOutput) {
            let output = this.filteredBalance(this.state.filteredTxtOutput);
            this.setState({filteredOutputBalance: output });
        }
    }

    formatData(data, decimal) {
        let value = parseFloat(data);

        return (value > 0) ? value.toFixed(decimal) : value;
    }

    filteredImageLink = (name, type, imgname=true) => {
        var path = "";

        if (name) {
            this.state.tokensBalance.filter(token => {
                if (token.slug === name) {
                    path = token.logo_url;
                }
            });
        }

        if (path) {
            return <><img src={path} className='coinicon' /> { imgname ? name : '' } </>;
        } else {
            if (type === "input") {
                return <><img src={eth} className='coinicon' /> { imgname ? name : '' } </>;
            } else {
                return <>Select</>;
            }
        }
    }

    filteredImage = (name, type, imgname=true) => {
        if (name) {
            var path = "assets/userpanel/images/color/"+name.toLowerCase()+".svg";
            return <><img src={path} alt={name} className='coinicon' /> { imgname ? name : '' }</>;
        } else {
            if (type === "input") {
                if (this.state.tokensBalance && this.state.tokensBalance.length > 0) {
                    var path1 = "assets/userpanel/images/color/"+this.state.tokensBalance[0].slug.toLowerCase()+".svg";

                    return <><img src={path1} alt={this.state.tokensBalance[0].name} className='coinicon' /> {this.state.tokensBalance[0].slug} </>;
                } else {
                    return <span>Select</span>
                }
            } else {
                return <span>Select</span>
            }
        }
    }

    filteredItemEvent = (name, balance, type, usd) => {
        console.log("filter 2");
        let fromContract = "";
        let toContract = "";

        this.setState({isBalLoading: true});

        if (type === "input") {
            this.setState({ filteredTxtInput: name, filteredInputBalance: balance, filteredTxtInputPercBal: 0 });

            this.showInputCoin();

            fromContract = this.filteredContract(name);
            toContract = this.filteredContract(this.state.filteredTxtOutput);

            this.checkLiquidityForView(fromContract, toContract);
        } else {
            this.setState({ filteredTxtOutput: name, filteredOutputBalance: balance, filteredTxtOutputPercBal: 0 });

            this.showOutputCoin();

            fromContract = this.filteredContract(this.state.filteredTxtInput);
            toContract = this.filteredContract(name);

            this.checkLiquidityForView(fromContract, toContract);
        }
    }

    filteredContract(name) {
        let contract = "";

        var filterInfo = this.state.tokensBalance.find((tokenInfo) => {
            return tokenInfo.slug == name;
        });

        if (filterInfo) {
            contract = filterInfo.contract_address;
        }

        return contract;
    }

    filteredBalance(name) {
        let balance = 0;

        var filterInfo = this.state.tokensBalance.find((tokenInfo) => {
            return tokenInfo.slug == name;
        });

        if (filterInfo) {
            balance = filterInfo.balance;
        }

        return balance;
    }

    getLiquidityList() {
        var params = {
            address: this.service.address(),
            network: this.service.network()
        };

        this.setState({ispairloading: true});

        this.service.getLiquidityList(params).then(res => {
            this.setState({ispairloading: false});

            if (res && res.data && res.data.data) {
                this.setState({liquidityList: res.data.data});
            }
        });
    }

    filterLiquidity = (name, contractAddress, type) => {
        console.log("filter");
        if (type === "input") {
            this.setState({ filteredTxtLiqtyInput: name, filteredTxtLiqtyInputContract: contractAddress, filteredTxtInput: name });

            this.showLiqInputCoin();

            if (contractAddress && this.state.filteredTxtLiqtyOutputContract) {
                this.checkLiquidity(contractAddress, this.state.filteredTxtLiqtyOutputContract);
            }
        } else {
            this.setState({ filteredTxtLiqtyOutput: name, filteredTxtLiqtyOutputContract: contractAddress, filteredTxtOutput: name });

            this.showLiqOutputCoin();

            if (this.state.filteredTxtLiqtyInputContract && contractAddress) {
                this.checkLiquidity(this.state.filteredTxtLiqtyInputContract, contractAddress);
            }
        }
    }
    
    checkLiquidityForView = (input, output) => {
        if (this.service.address() && input && output) {
            var params = {
                address_input: input,
                address_output: output,
                from_address: this.service.address(),
                network: this.service.network()
            };

            this.service.checkLiquidity(params).then(res => {
                if (res.success) {
                    if (res.data && res.data.result) {
                        this.setState({liquidityData: res.data});
                        this.service.setLiquidity(res.data.result);
                        this.setState({isBalLoading: false});
                    }
                }
            });
        } else {
            this.setState({isBalLoading: false});
        }
    }

    checkLiquidity = (input, output) => {
        if (this.service.address()) {
            var params = {
                address_input: input,
                address_output: output,
                from_address: this.service.address(),
                network: this.service.network()
            };

            this.setState({isLiqLoading: true, isShowInputAlert: false, isShowOutputAlert: false});

            this.service.checkLiquidity(params).then(res => {
                if (res.success) {
                    if (res.data && res.data.result) {
                        let isFirstL = (res.data.error) ? true : false;
                        this.setState({liquidityData: res.data, isFirstLiq: isFirstL});
                        this.service.setLiquidity(res.data.result);
                        // this.showLiquidityView();
                    } else {
                        this.setState({liqAlertShow: true, isShowAddLiq: true, isLiqLoading: false});
                    }
                }
            });
        }
    }

    showLiquidityView = () => {
        var input = localStorage.getItem("balance") ? localStorage.getItem("balance") : 0;
        var output = 0;

        input = this.filteredBalance(this.state.filteredTxtInput);
        output = this.filteredBalance(this.state.filteredTxtOutput);

        this.setState({isEnableLiqView: true, filteredInputBalance: input, filteredOutputBalance: output});
    }

    withoutRoundValue = (value, decimal) => {
        var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (decimal || -1) + '})?');
        return value.toString().match(re)[0];
    }

    filteredLiquidityPair = (data) => {
        let rmLiqAllowence = false;
        let liqAmtFormat = this.formatData(data.balance, 8);
        // let liqAmt = this.withoutRoundValue(data.balance, 8);
        let liqAmt = data.balance;

        if (parseFloat(data.pairAllowance) < parseFloat(liqAmt)) {
            rmLiqAllowence = true;
        }

        this.setState({isShowLiqPair: true, filteredPairData: data, liqAmt: liqAmt, rmLiqAllowence: rmLiqAllowence});

        if (rmLiqAllowence) {
            this.setState({removeApproveLiqLoading: true});
        } else {
            this.setState({removeLiqLoading: true});
        }

        var params = {
            amt : data.balance,
            input_address: data.token0_address,
            ouput_address: data.token1_address,
            pair_address: data.pairAddress,
            network: this.service.network()
        };

        this.service.getLiquidityPairData(params).then(res => {
            if (res && res.data) {
                this.setState({
                    liqInBal: res.data.token0Out,
                    liqOutBal: res.data.token1Out,
                    pairData: res.data
                });

                if (rmLiqAllowence) {
                    this.setState({removeApproveLiqLoading: false});
                } else {
                    this.setState({removeLiqLoading: false});
                }
            }
        });
    }

    showHideLiqPair = () => {
        if (!this.state.isPayPopup) {
          this.setState({ isShowLiqPair: !this.state.isShowLiqPair, removeLiqLoading: false, removeApproveLiqLoading: false });
        } else {
          this.payPopupAction();
        }
    }

    liqAmtEvent = (event) => {
        var amt = event.target.value.replace(/[^0-9\.]/g,'');

        var rmApprove = false;

        if (this.state.filteredPairData.pairAllowance < amt) {
            rmApprove = true;
        }

        this.setState({ liqAmt: amt, rmLiqAllowence: rmApprove});

        var params = {
            liqAmt: amt,
            orgData: this.state.pairData
        };

        this.service.calLiquidityData(params).then(res => {
            if (res) {
                this.setState({
                    liqInBal: res.token0Out,
                    liqOutBal: res.token1Out
                });
            }
        });
    }

    removeLiqEvent = () => {
        var params = {
            input_address: this.state.filteredPairData.token0_address,
            ouput_address: this.state.filteredPairData.token1_address,
            toaddress: this.service.address(),
            liq_in_amt: this.state.liqInBal,
            liq_out_amt: this.state.liqOutBal,
            amt: this.state.liqAmt,
            network: this.service.network()
        };

        this.setState({removeLiqLoading: true});

        this.service.removeLiquidityPair(params).then(res => {
            if (res && res.data) {
                this.removeLiquidity(res.data);
            }
        });
    }

    removeLiquidity(data) {
        this.exchangeUtilRef.current.removeLiqEvt(data);
    }

    removeLiquidityEvt = (data) => {
        if (data.hash) {
            toast.success("Pair is removed from liquidity");

            this.setState({removeLiqLoading: false});
            this.showHideLiqPair();
            this.getLiquidityList();
        } else {
            toast.error("Cancelled the pair remove liquidity");

            this.setState({removeLiqLoading: false});
        }
    }

    warningMessage = (data) => {
        toast.error(data);

        this.setState({removeLiqLoading: false, removeApproveLiqLoading: false, isBalLoading: false});
    }

    amtCalInputEvent = (event) => {
        var inAmt = event.target.value.replace(/[^0-9\.]/g,'');

        if (inAmt.split('.').length > 2) {
            return false;
        }

        if (this.state.filteredTxtInput && this.state.filteredTxtOutput) {
            var params = {
                inputVal: inAmt,
                inputOrg: this.state.filteredInputBalance,
                outputOrg: this.state.filteredOutputBalance,
                token2: this.state.liquidityData ? this.state.liquidityData.result.token2price : 0,
                token1allowance: this.state.liquidityData ? this.state.liquidityData.result.token1allowance : 0,
                token2allowance: this.state.liquidityData ? this.state.liquidityData.result.token2allowance : 0,
                isFirstL: this.state.isFirstLiq
            };

            this.service.amtCalInputEvent(params).then(res => {
                this.setState(res);
                this.quoteAddLiquidity(res.inputAmt, res.outputAmt);
            });
        }
    }

    amtCalOutputEvent = (event) => {
        var outputVal = event.target.value.replace(/[^0-9\.]/g,'');

        if (outputVal.split('.').length > 2) {
            return false;
        }

        var params = {
            inputOrg: this.state.filteredInputBalance,
            outputVal: outputVal,
            outputOrg: this.state.filteredOutputBalance,
            token1: this.state.liquidityData ? this.state.liquidityData.result.token1price : 0,
            token2allowance: this.state.liquidityData ? this.state.liquidityData.result.token2allowance : 0,
            isFirstL: this.state.isFirstLiq
        }

        this.service.amtCalOutputEvent(params).then(res => {
            this.setState(res);
            this.quoteAddLiquidity(res.inputAmt, res.outputAmt);
        });
    }

    showModal = () => {
        this.setState(prevState => ({
            show: !prevState.show
        }));
    }

    showLiqInputCoin = () => {
        if (this.state.isPaid) {
          this.setState(prevState => ({
              inputLiqCoinShow: !prevState.inputLiqCoinShow
          }));
        } else {
          this.setState({ isPayPopup: true });
        }
    }

    showLiqOutputCoin = () => {
        if (this.state.isPaid) {
          this.setState(prevState => ({
              outputLiqCoinShow: !prevState.outputLiqCoinShow
          }));
        } else {
          this.setState({ isPayPopup: true });
        }
    }

    showInputCoin = () => {
        if (this.state.isPaid) {
            this.setState(prevState => ({
                inputCoinShow: !prevState.inputCoinShow
            }));
        } else {
            this.payPopupAction();
        }
    }

    showOutputCoin = () => {
        if (this.state.isPaid) {
            this.setState(prevState => ({
                outputCoinShow: !prevState.outputCoinShow
            }));
        } else {
            this.payPopupAction();
        }
    }

    connectWalletEvent = () => {
        eventBus.dispatch("connect");
    }

    quote(amount1, reserve1, reserve2) {
        var amount2 = amount1 * (reserve2 / reserve1);

        return amount2;
    }

    quoteAddLiquidity(amountADesired, amountBDesired) {
        if (this.state.liquidityData.result.reserveA == undefined && this.state.liquidityData.result.reserveB == undefined) {
            const amountOut = Number(amountADesired) * Number(amountBDesired);

            this.setState({receiveTokens: amountOut});
            this.sharePoolCal(amountOut);
        } else if (this.state.liquidityData.result.reserveA === 0 && this.state.liquidityData.result.reserveB === 0) {
            var amountOut = this.quoteMintLiquidity(amountADesired, amountBDesired);

            this.setState({receiveTokens: amountOut});
            this.sharePoolCal(amountOut);
        } else {
            const amountBOptimal = this.quote(amountADesired, this.state.liquidityData.result.reserveA, this.state.liquidityData.result.reserveB);

            if (amountBOptimal <= amountBDesired) {
                const amountOut = this.quoteMintLiquidity(amountADesired, amountBOptimal);

                this.setState({receiveTokens: amountOut});
                this.sharePoolCal(amountOut);
            } else {
                const amountAOptimal = this.quote(amountBDesired,this.state.liquidityData.result.reserveB,this.state.liquidityData.result.reserveA);
                const amountOut = this.quoteMintLiquidity(amountAOptimal, amountBDesired);

                this.setState({receiveTokens: amountOut});
                this.sharePoolCal(amountOut);
            }
        }
    }

    quoteMintLiquidity(amountA, amountB){
        const MINIMUM_LIQUIDITY = 1000;
        const valueA = amountA*(10**this.state.liquidityData.result.token1Decimals);
        const valueB = amountB*(10**this.state.liquidityData.result.token2Decimals);
        const reserve_a = this.state.liquidityData.result.reserveA *(10**this.state.liquidityData.result.token1Decimals);
        const reserve_b = this.state.liquidityData.result.reserveB*(10**this.state.liquidityData.result.token2Decimals);

        if (this.state.liquidityData.result.totalSupply === 0){
            return Math.sqrt(((reserve_a * reserve_b)-MINIMUM_LIQUIDITY))*10**(-18);
        };

        var tot = Math.min(valueA*this.state.liquidityData.result.totalSupply/reserve_a, valueB*this.state.liquidityData.result.totalSupply/reserve_b);

        return isNaN(tot) ? 0 : tot;
    }

    sharePoolCal(receiveTokens) {
        var total = receiveTokens + this.state.liquidityData.result.totalSupply;
        var perc = (receiveTokens / total) * 100;

        this.setState({poolShare: perc});
    }

    sharePoolTem = () => {
        var perc = this.state.poolShare;

        if (perc > 0.0001) {
            perc = this.formatData(perc, 4);
        } else if (perc < 0.0001 && perc !== 0) {
            perc = ('<' + 0.0001);
        } else {
            perc = 0;
        }

        return perc;
    }

    showLiquidityBtn = () => {
        return (!this.state.isShowInputAllowence &&
        !this.state.isShowOutputAllowence &&
        (parseFloat(this.state.inputAmt) > 0) &&
        (parseFloat(this.state.outputAmt) > 0) &&
        !this.state.isShowInputAlert &&
        !this.state.isShowOutputAlert) ? true : false;
    }

    maxLimitEvet = (type) => {
        var inAmt = 0;
        var outAmt = 0;
        var params = {};

        if (type === "input") {
            inAmt = this.formatData(this.state.filteredInputBalance, 6);
            outAmt = this.formatData(this.state.filteredOutputBalance, 6);

            params = {
                inputVal: inAmt,
                outputVal: outAmt,
                outputOrg: outAmt,
                token2: this.state.liquidityData ? this.state.liquidityData.result.token2price : 0,
                token1allowance: this.state.liquidityData ? this.state.liquidityData.result.token1allowance : 0,
                token2allowance: this.state.liquidityData ? this.state.liquidityData.result.token2allowance : 0
            }

            this.service.amtCalInputEvent(params).then(res => {
                this.setState(res);
            });
        } else {
            inAmt = this.formatData(this.state.filteredInputBalance, 6);
            outAmt = this.formatData(this.state.filteredOutputBalance, 6);

            params = {
                inputVal: inAmt,
                outputVal: outAmt,
                inputOrg: inAmt,
                token1: this.state.liquidityData ? this.state.liquidityData.result.token1price : 0,
                token2allowance: this.state.liquidityData ? this.state.liquidityData.result.token2allowance : 0,
            }

            this.service.amtCalOutputEvent(params).then(res => {
                this.setState(res);
            });
        }
    }

    outputPriceRate = () => {
        var rate = 0;
        var token1 = this.state.liquidityData ? this.state.liquidityData.result.token1 : 0;
        var token2 = this.state.liquidityData ? this.state.liquidityData.result.token2 : 0;

        rate = (token2 > 0) ? (token1 / token2) : 0;

        return rate.toFixed(8);
    }

    approveTokenEvent = (type) => {
        var params = {
            slug: (type === "input") ? this.state.filteredTxtInput : this.state.filteredTxtOutput,
            amount: (type === "input") ? this.state.inputAmt : this.state.outputAmt,
            from_address: this.service.address(),
            network: this.service.network()
        };

        this.setState({isBalLoading: true});

        this.service.approveToken(params).then(res => {
            if (res.success) {
                var params = { approveFilterType: type };

                this.setState(params);
                this.tokenApproveProvider(res.data);
            }
        });
    }

    approveRemoveTokenEvent = () => {
        var params = {
            pair_address: this.state.filteredPairData.pairAddress,
            amount: this.state.liqAmt,
            network: this.service.network()
        };

        this.setState({removeApproveLiqLoading: true});

        this.service.removeApproveToken(params).then(res => {
            if (res.success) {
                // this.setState({rmLiqAllowence: false});
                this.removeTokenApproveProvider(res.data);
            }
        });
    }

    removeTokenApproveProvider(data) {
        this.exchangeUtilRef.current.removeTokenApprove(data);
    }

    removeTokenApproveProviderEvt = (data) => {
        if (data.hash) {
            toast.success("Approved successfully");
            this.setState({rmLiqAllowence: false, removeApproveLiqLoading: false});
        } else {
            toast.error("Approved Cancelled");

            this.setState({removeApproveLiqLoading: false, rmLiqAllowence: true});
        }
    }

    tokenApproveProvider(data) {
        this.exchangeUtilRef.current.tokenApprove(data);
    }

    tokenApproveConfirmed = (data) => {
        if (data.hash) {
            if (this.state.approveFilterType === "input") {
                toast.success(this.state.filteredTxtInput + " approved successfully");

                this.setState({isShowInputAllowence: false, isBalLoading: false});

            } else {
                toast.success(this.state.filteredTxtOutput + " approved successfully");

                this.setState({isShowOutputAllowence: false, isBalLoading: false});
            }
        } else {
            toast.error("Approve Cancelled");
        }
    }

    addLiquidity = () => {
        var params = {
            input_slug: this.state.filteredTxtInput,
            output_slug: this.state.filteredTxtOutput,
            input_amt: this.state.inputAmt.toString(),
            output_amt: this.state.outputAmt.toString(),
            from_address: this.service.address(),
            network: this.service.network()
        };

        this.setState({isBalLoading: true});

        this.service.addLiquidity(params).then(res => {
            if (res.data) {
                this.addLiquidityProvider(res.data);
            }
        });
    }

    addLiquidityProvider(data) {        
        this.exchangeUtilRef.current.addLiquidity(data);
    }

    addLiquidityConfirmed = (data) => {
        if (data.hash) {
            toast.success("Tokens added to liquidity");
            this.setState({isShowAddLiq: false, isLiqLoading: false, isBalLoading: false, inputAmt: 0, outputAmt: 0});
            this.getLiquidityList();
        } else {
            toast.error("Transaction Cancelled");

            this.setState({isBalLoading: false});
        }
    }

    menuActiveIndicator = (name) => {
        return (window.location.pathname === name) ? 'active' : '';
    }

    filterPercentage = (event) => {
        var perc = event.currentTarget.dataset['value'];
        var maxPerc = 100;

        this.setState({
            slippagePerc: perc
        })
    }

    payEvent = (event) => {
        if (this.state.filteredInputBalance > this.state.payAmt) {
            let params = {
                address: this.service.address(),
                amount: this.state.payAmt,
                network: this.service.network()
            };

            this.setState({payLoading: true});

            this.service.paymentConfirmation(params).then(res => {
                if (res.data) {
                this.paymentProccessEvt(res.data);
                }
            });
        } else {
            toast.error("Insufficient fund in your wallet");
        }
    }

    paymentProccessEvt(data) {
        const transactionParameters = {
            to: data.to,
            from: this.service.address(),
            value: data.value,
            data: data.data
        };

        this.paymentUtilRef.current.paymentEvt(transactionParameters);
    }

    paymentProccess = (data) => {
        if (data.hash) {
            this.setState({ isPayPopup: false, payLoading: false, isPaid: true });
            toast.success("Payment is successfully!.");
            this.service.updatePayment({address: this.service.address(), amount: this.state.payAmt, txid: data.hash, network: this.service.network()});
        } else {
            toast.error("Payment Cancelled");

            this.setState({ payLoading: false });
        }
    }

    payWarningMessage = (data) => {
        toast.error(data);

        this.setState({payLoading: false});
    }

    render() {
        return <div>
            <section className="topbanner panelcontentbox">
                <div className="container sitecontainer">
                    <ul className="nav nav-tabs tabbanner innerpagetab">
                        <li className={`nav-item ${ this.menuActiveIndicator('/swap') }`}>
                            <Link to="/swap" className={`nav-link ${ this.menuActiveIndicator('/swap') }`}>Swap</Link>
                        </li>
                        <li className={`nav-item ${ this.menuActiveIndicator('/liquidity') }`}>
                            <Link to="/liquidity" className={`nav-link ${ this.menuActiveIndicator('/liquidity') }`}>Liquidity</Link>
                        </li>
                    </ul>
                </div>
            </section>
            <article className="gridparentbox exchangegridbox">

                <div className="container sitecontainer liquiditypage">
                    <section className="innerpagecontent stackedbanner">
                        <div className="container sitecontainer">
                            <div className="topcontentbox">
                                <h3 className="heading-title pb-2">Just stake some tokens to earn</h3>
                            </div>
                        </div>
                    </section>

                    <div className="panelcontentbox mb-4">
                        { (this.state.isEnableLiqView) ?
                                <div className="flexbox contentbox">
                                    <div className="swapbox">
                                        <h2 className="heading-box">Add Liquidity Pool</h2>
                                        <div>
                                            <div className="siteformbox">
                                                {
                                                    this.state.isShowInputAlert &&
                                                    <div className="alert-org-color alert alert-danger "><i className="fa fa-info-circle"></i> Insufficient {this.state.filteredTxtInput} Balance </div>
                                                }

                                                <div className={`form-group cryptobg ${this.state.isBalLoading ? 'common-loading pe-none' : ''} `}>
                                                    <div className="input-group">
                                                        <span className="input-group-text">
                                                            <div className="convertbox">
                                                                <a onClick={this.showInputCoin}>
                                                                    {this.filteredImageLink(this.state.filteredTxtInput, 'input')}

                                                                    <i><img src={downarrow} /></i>
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <ul className="settinglimit">
                                                                    <li><a onClick={() => this.maxLimitEvet("input")}>Max</a></li>
                                                                </ul>
                                                            </div>
                                                        </span>
                                                        <input className="form-control form-control-lg" value={this.state.inputAmt} onChange={this.amtCalInputEvent} pattern="[+-]?\d+(?:[.,]\d+)?" />
                                                    </div>

                                                    <label className="labelbox"> <span className="pull-right text-right">
                                                        <span>Available <span className="ps-2">{this.formatData(this.state.filteredInputBalance, 6)}</span></span>
                                                    </span>
                                                    </label>
                                                </div>
                                                <div className="form-group text-center">
                                                    {this.state.isBalLoading &&
                                                        <ReactLoading
                                                            type="bars"
                                                            color="#3d85c6"
                                                            height={'6%'}
                                                            width={'6%'}
                                                            className="flex flex-wrap content-center justify-center w-5 h-5 bg-blue w-5 ma2 h4 items-center justify-center flex flex-column flex-wrap mx-auto"
                                                        />
                                                    }
                                                </div>

                                                {
                                                    this.state.isShowOutputAlert &&
                                                    <div className="alert-org-color alert alert-danger"><i className="fa fa-info-circle"></i> Insufficient {this.state.filteredTxtOutput} Balance </div>
                                                }

                                                <div className={`form-group cryptobg ${this.state.isBalLoading ? 'common-loading pe-none' : ''} `}>
                                                    <div className="input-group">
                                                        <span className="input-group-text">
                                                            <div className="convertbox">
                                                                <a onClick={this.showOutputCoin}>
                                                                    {this.filteredImageLink(this.state.filteredTxtOutput, 'output')}
                                                                    <i><img src={downarrow} /></i>
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <ul className="settinglimit">
                                                                    <li><a onClick={() => this.maxLimitEvet("output")}>Max</a></li>
                                                                </ul>
                                                            </div>
                                                        </span>

                                                        <input className="form-control form-control-lg" value={this.state.outputAmt} onChange={this.amtCalOutputEvent} />
                                                    </div>
                                                    <label className="labelbox">
                                                        <span className="pull-right text-right">
                                                            <span>Available<span className="ps-2">{this.formatData(this.state.filteredOutputBalance, 6)}</span></span>
                                                        </span>
                                                    </label>
                                                </div>

                                                <div className={ (this.state.isShowInputAllowence && this.state.isShowOutputAllowence) ? 'd-flex' : 'text-center' }>
                                                    { this.state.isShowInputAllowence &&
                                                        <div className="col-md-6 p-1">
                                                            <button className={`btn sitebtn btn-block ${this.state.isBalLoading ? 'common-loading pe-none' : ''} `} onClick={() => this.approveTokenEvent('input')}>Approve { this.state.filteredTxtInput }</button>
                                                        </div>
                                                    }

                                                    { this.state.isShowOutputAllowence &&
                                                        <div className="col-md-6 p-1">
                                                            <button className={`btn sitebtn btn-block ${this.state.isBalLoading ? 'common-loading pe-none' : ''} `} onClick={() => this.approveTokenEvent('output')}>Approve { this.state.filteredTxtOutput }</button>
                                                        </div>
                                                    }
                                                </div>

                                                {
                                                    this.showLiquidityBtn() &&
                                                        <button className={`btn sitebtn btn-block ${this.state.isBalLoading ? 'common-loading pe-none' : ''} `} onClick={() => this.addLiquidity() }>Add Liquidity</button>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="swapboxwallet">
                                        <div className="paneldarkbox boxheight">
                                            <div className={`alertboxcenter ${ !this.service.address() ? '' : 'd-none' } `}>
                                                <div>
                                                    <ConnectWallet name="user"></ConnectWallet>
                                                </div>
                                            </div>

                                            <div className={`alertboxcenter ${ (this.service.address() && this.state.liquidityList.length == 0) ? '' : 'd-none' } `}>
                                                <div>
                                                    <img src={nodata} className="nodataicon" />
                                                    <h4>You don’t have liquidity in this pool yet.</h4>
                                                </div>
                                            </div>

                                            <div className={`alertboxcenter ${ (this.service.address() && this.state.liquidityList.length > 0) ? '' : 'd-none' } `}>
                                                <div>
                                                    <ConnectWallet name="user"></ConnectWallet>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="paneldarkbox d-none">
                                            <div className="flexbox liquidtybalance">
                                                <div className="blancetext">
                                                    <div className="form-group">
                                                        <p><b>Price Rate</b></p>
                                                        <p className="t-blue">
                                                            { this.filteredImageLink(this.state.filteredTxtInput, 'input', false) }

                                                            1 { this.state.filteredTxtInput } = { this.state.liquidityData ? this.state.liquidityData.result.token2price : 0 } { this.state.filteredTxtOutput }

                                                        </p>
                                                        <p className="t-blue">
                                                            { this.filteredImageLink(this.state.filteredTxtOutput, 'output', false) }

                                                            1 { this.state.filteredTxtOutput } = { this.state.liquidityData ? this.state.liquidityData.result.token1price : 0 } { this.state.filteredTxtInput }
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p><b>Estimate Pool Allocation</b></p>
                                                        <p className="t-blue">
                                                            { this.filteredImageLink(this.state.filteredTxtInput, 'input', false) }

                                                            { this.state.inputAmt} { this.state.filteredTxtInput }
                                                        </p>
                                                        <p className="t-blue">
                                                            { this.filteredImageLink(this.state.filteredTxtOutput, 'output', false) }

                                                            { this.state.outputAmt} { this.state.filteredTxtOutput }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="blancetext">
                                                    <div className="form-group">
                                                        <p><b>You will Recieve</b></p>
                                                        <p className="t-blue">
                                                            { this.formatData(this.state.receiveTokens, 8) }

                                                            { this.filteredImageLink(this.state.filteredTxtInput, 'input', false) }
                                                            { this.filteredImageLink(this.state.filteredTxtOutput, 'output', false) }

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :

                            <div className="flexbox contentbox">
                                <div className="swapbox">
                                    <h2 className="heading-box">Import Pool</h2>
                                    {/* <div className="tabrightbox">
                                        <a className="iconbtn" onClick={this.showModal}><i className="fa fa-cog"></i></a>
                                    </div> */}

                                    <div>
                                        <form className="siteformbox">
                                            <div className={`form-group cryptobg importpoolbox ${this.state.isLiqLoading ? 'common-loading pe-none' : ''} `}>
                                                <span className="input-group-text">
                                                    <div className="convertbox">
                                                        <a onClick={this.showLiqInputCoin}>
                                                            {this.filteredImageLink(this.state.filteredTxtLiqtyInput, 'input', true) }

                                                            <i><img src={downarrow} /></i>
                                                        </a>
                                                    </div>
                                                </span>
                                            </div>
                                            <div className="form-group text-center">
                                                {this.state.isLiqLoading ?
                                                    <ReactLoading type="bars" color="#3d85c6" height={'6%'} width={'6%'} className="flex flex-wrap content-center justify-center w-5 h-5 bg-blue w-5 ma2 h4 items-center justify-center flex flex-column flex-wrap mx-auto" />
                                                    :
                                                    <i className="fa fa-plus"></i>
                                                }
                                            </div>
                                            <div className={`form-group cryptobg importpoolbox ${this.state.isLiqLoading ? 'common-loading pe-none' : ''} `}>
                                                <span className="input-group-text">
                                                    <div className="convertbox">
                                                        <a onClick={this.showLiqOutputCoin}>
                                                            {this.filteredImageLink(this.state.filteredTxtLiqtyOutput, 'output') }

                                                            <i><img src={downarrow} /></i>
                                                        </a>
                                                    </div>
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="swapboxwallet">
                                    <div className="paneldarkbox boxheight">
                                        <div className={`alertboxcenter ${ !this.service.address() ? '' : 'd-none' } `}>
                                            <div>
                                                <ConnectWallet name="user"></ConnectWallet>
                                            </div>
                                        </div>

                                        <div className={`alertboxcenter ${ (this.service.address() && this.state.liquidityList.length == 0) ? '' : 'd-none' } `}>
                                            <div>
                                                <img src={nodata} className="nodataicon" />
                                                <h4>You don’t have liquidity in this pool yet.</h4>
                                            </div>
                                        </div>

                                        <div className={`alertboxcenter ${ (this.service.address() && this.state.liquidityList.length > 0) ? '' : 'd-none' } `}>
                                            <div>
                                                <ConnectWallet name="user"></ConnectWallet>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="panelcontentbox mt-3 liquiditytable">
                        <div className="contentbox">
                            <div className="table-responsive" data-simplebar>
                                <table className="table sitetable">
                                    <thead>
                                        <tr>
                                            <th>Coin</th>
                                            <th className="text-center">Balance</th>
                                            <th className="text-right">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.state.ispairloading ?
                                            <tr><td colSpan={3} className="text-center common-loading pe-none"><ReactLoading type="bars" color="#3d85c6" height={'3%'} width={'3%'} className="flex flex-wrap content-center justify-center w-5 h-5 bg-blue w-5 ma2 h4 items-center justify-center flex flex-column flex-wrap mx-auto" /></td></tr>
                                            :

                                            (this.state.liquidityList.length > 0) ?
                                                this.state.liquidityList.map((data, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            <img src={`${process.env.REACT_APP_TOKEN_PATH}${data.token0_name.toLowerCase()}.svg`} className="coinicon" />
                                                            <img src={`${process.env.REACT_APP_TOKEN_PATH}${data.token1_name.toLowerCase()}.svg`} className="coinicon coinlefticon" />
                                                            {data.token0_name}_{data.token1_name}
                                                        </td>
                                                        <td className="text-center"> {this.formatData(data.balance, 8)} </td>
                                                        <td className="text-right"><a className="btn sitebtn btn-sm" onClick={() => this.filteredLiquidityPair(data)}>Remove</a></td>
                                                    </tr>
                                                ))
                                            :
                                            <tr><td colSpan={3} className="text-center">No result found.</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div></div>

                    </div>
                </div>

                <Modal
                    show={this.state.inputCoinShow}
                    onHide={() => this.showInputCoin}
                    dialogClassName="modalbgt cryptomodal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">Select Coin </Modal.Title>
                        <button className="close" onClick={this.showInputCoin}>&times;</button>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="siteformbox">
                            <TokenComponent
                                data={this.state.tokensBalance}
                                name="input"
                                clickHandler={this.filteredItemEvent}
                                filterTxtName={this.state.filteredTxtOutput}
                            ></TokenComponent>
                        </form>
                    </Modal.Body>
                </Modal>

                <Modal
                show={this.state.outputCoinShow}
                onHide={() => this.showOutputCoin}
                dialogClassName="modalbgt cryptomodal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Select Coin</Modal.Title>
                    <button className="close" onClick={this.showOutputCoin}>&times;</button>
                </Modal.Header>
                <Modal.Body>
                    <form className="siteformbox">
                        <TokenComponent
                            data={this.state.tokensBalance}
                            name="output"
                            clickHandler={this.filteredItemEvent}
                            filterTxtName={this.state.filteredTxtInput}
                        ></TokenComponent>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={this.state.inputLiqCoinShow}
                onHide={() => this.showLiqInputCoin}
                dialogClassName="modalbgt cryptomodal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Select Coin </Modal.Title>
                    <button className="close" onClick={this.showLiqInputCoin}>&times;</button>
                </Modal.Header>
                <Modal.Body scrollable="true">
                    <form className="siteformbox">
                        <TokenListWithBalanceComponent
                            data={this.state.tokensBalance}
                            name="input"
                            clickHandler={this.filterLiquidity}
                            filterTxtName={this.state.filteredTxtLiqtyOutput}
                        ></TokenListWithBalanceComponent>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={this.state.outputLiqCoinShow}
                onHide={() => this.showLiqOutputCoin}
                dialogClassName="modalbgt cryptomodal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Select Coin</Modal.Title>
                    <button className="close" onClick={this.showLiqOutputCoin}>&times;</button>
                </Modal.Header>
                <Modal.Body>
                    <form className="siteformbox">
                        <TokenListWithBalanceComponent
                            data={this.state.tokensBalance}
                            name="output"
                            clickHandler={this.filterLiquidity}
                            filterTxtName={this.state.filteredTxtLiqtyInput}
                        ></TokenListWithBalanceComponent>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={this.state.isShowLiqPair}
                onHide={() => this.showHideLiqPair}
                size="md"
                dialogClassName="modalbgt liquiditymodalb"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Remove Liquidity</Modal.Title>
                    <button className="close" onClick={this.showHideLiqPair}>&times;</button>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.isShowLiqPair &&
                        <div className="siteformbox pt-4">
                            <div className="form-group cryptobg">
                                <label>How much you want remove?</label>
                                <div className="input-group">
                                    <input className="form-control text-white form-control-lg" name="liqAmt" value={ this.state.liqAmt } onChange={this.liqAmtEvent}  readOnly />
                                    <span className="input-group-text">
                                        <img src={`${process.env.REACT_APP_TOKEN_PATH}${this.state.filteredPairData.token0_name.toLowerCase()}.svg`} className="coinicon" alt={this.state.filteredPairData.token0_name.toLowerCase()} />
                                        <img src={`${process.env.REACT_APP_TOKEN_PATH}${this.state.filteredPairData.token1_name.toLowerCase()}.svg`} className="coinicon coinlefticon" alt={this.state.filteredPairData.token1_name.toLowerCase()} />
                                        { this.state.filteredPairData.token0_name }_{ this.state.filteredPairData.token1_name }
                                    </span>
                                </div>
                            </div>
                            <div className="form-group blancetext">
                                <span>{ this.state.filteredPairData.token0_name } Amount</span>
                                <span className="text-right pull-right t-blue">{ this.formatData(this.state.liqInBal, 6) }</span>
                            </div>
                            <div className="form-group blancetext">
                                <span>{ this.state.filteredPairData.token1_name } Amount</span>
                                <span className="text-right pull-right t-blue">{ this.formatData(this.state.liqOutBal, 6) }</span>
                            </div>

                            <div className='text-center'>
                                { this.state.rmLiqAllowence &&
                                    <button type="button" className={`btn sitebtn btn-block ${this.state.removeApproveLiqLoading ? 'common-loading pe-none' : ''} `} onClick={ () => this.approveRemoveTokenEvent() }>Approve</button>
                                }
                            </div>

                            { (!this.state.rmLiqAllowence) &&
                                <div className="form-group">
                                    <button type="button" className={`btn sitebtn btn-block ${this.state.removeLiqLoading ? 'common-loading pe-none' : ''} `} onClick={ () => this.removeLiqEvent() }>Submit</button>
                                </div>
                            }

                            {this.state.removeLiqLoading &&
                                <ReactLoading
                                    type="bars"
                                    color="#3d85c6"
                                    height={'6%'}
                                    width={'6%'}
                                    className="flex flex-wrap content-center justify-center mx-auto"
                                />
                            }

                            {this.state.removeApproveLiqLoading &&
                                <ReactLoading
                                    type="bars"
                                    color="#3d85c6"
                                    height={'6%'}
                                    width={'6%'}
                                    className="flex flex-wrap content-center justify-center mx-auto"
                                />
                            }
                        </div>
                    }
                </Modal.Body>
            </Modal>

            <Modal
                show={this.state.isPayPopup}
                onHide={() => this.payPopupAction}
                size="sm"
                dialogClassName="modalbgt"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Access To Swap</Modal.Title>
                    <button className="close" onClick={this.payPopupAction}>&times;</button>
                </Modal.Header>
                <Modal.Body>
                    <p className="fs-5">If you want to access the swap, you should pay the processing fee of <b>{ this.state.payAmt } { (this.service.network() == "polygon") ? "MATIC" : "ETH" }</b>.</p>
                </Modal.Body>
                <Modal.Footer>
                  { (this.service.address()) && 
                    <div className="text-center">
                        <button className={`text-center btn btn-sm sitebtn ${this.state.payLoading ? 'common-loading pe-none' : ''} `} onClick={() => this.payEvent()}>Pay</button>
                    </div>
                  }

                {this.state.payLoading &&
                    <ReactLoading
                        type="bars"
                        color="#3d85c6"
                        height={'6%'}
                        width={'6%'}
                        className="flex flex-wrap content-center justify-center mx-auto"
                    />
                }

                { !this.service.address() &&
                    <div className="text-center">
                        <ConnectWallet name="menu"></ConnectWallet>
                    </div>
                }
                </Modal.Footer>
            </Modal>

            <ToastContainer autoClose={5000} />

            <ExchangeUtil
                ref={this.exchangeUtilRef}
                clickHandlerLiquidity={this.removeLiquidityEvt}
                clickHandlerExchangeError={this.warningMessage}
                clickHandlerRemoveTokenApprove={this.removeTokenApproveProviderEvt}
                clickHandlerTokenApprove={this.tokenApproveConfirmed}
                clickHandlerAddLiquidity={this.addLiquidityConfirmed}
            ></ExchangeUtil>
            <PaymentUtil ref={this.paymentUtilRef} clickHandlerPay={this.paymentProccess} clickHandlerPayError={this.payWarningMessage}></PaymentUtil>
        </article>
        </div>
    }
}

export default Exchange;
