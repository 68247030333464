import React from "react";
import { Link } from 'react-router-dom';

import logo from './../../assets/images/logo.svg' ;

import Newsletter from "../Newsletter/Newsletter";

class AppFooter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            emailErrorMSg: ""
        };
    }

    getYear() {
        return new Date().getFullYear();
    }

    render() {
        return (
            <footer className="footerbottom userfooter">
                <section className="footer-gray-bg fnt-reg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="footlistbg">
                                    <div className="footlofo">
                                        <img src={logo} alt="Logo" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <Newsletter></Newsletter>
                            </div>
                        </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12 mx-auto text-center">
                            <ul className="foot-list">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/swap">Trade</Link></li>
                                <li><Link to="/farms">Earn</Link></li>
                                <li><Link to="/terms">Terms and Conditions</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><Link to="/tokenlisting">Token Listing</Link></li>
                                <li><Link to="/contact">Contact US</Link></li>
                                <li><Link to="/markets">Markets</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="text-center">
                        <p className="ftext">{this.getYear()} © { process.env.REACT_APP_NAME }. All Rights Reserved.</p>
                    </div>
                    </div>
                </section>
            </footer>
        )
    }
}

export default AppFooter;
