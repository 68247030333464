import React from "react";
import { Link } from 'react-router-dom';

import ConnectWallet from '../connectwallet/ConnectWallet';
import Theme from '../theme/Theme';
import Networks from '../network/Networks';

import logo from './../../assets/images/logo.svg';
import eth from './../../assets/images/color/eth.svg';
import poly from './../../assets/images/color/matic.svg';

class AppHeader extends React.Component {
    menuActiveIndicator = (name) => {
        return (window.location.pathname === name) ? 'active' : '';
    }

    render() {
        return (<header className="headermenu">
            <nav className="navbar navbar-expand-md navbar-dark headbg userheader">
                <div className="container">
                    <Link className="navbar-brand" to="/"> <img src={logo} className="logo" /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar"> <span className="navbar-toggler-icon"></span> </button>

                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav">
                            <li className={`nav-item ${ this.menuActiveIndicator('/dashboard') }`}><Link to="/dashboard" className="nav-link">Dashboard</Link></li>
                            <li className="nav-item dropdown"><a href="#" role="button" className="nav-link dropdown-bs-toggle" data-bs-toggle="dropdown">Trade<i className="fa fa-caret-down"></i></a>
                                <div className="dropdown-menu">
                                    <Link to="/swap" className={`nav-link dropdown-item ${ this.menuActiveIndicator('/swap') }`}>Swap</Link>
                                    <Link to="/liquidity" className={`nav-link dropdown-item ${ this.menuActiveIndicator('/liquidity') }`}>Liquidity</Link>
                                </div>
                            </li>
                            <li className="nav-item dropdown"><a href="#" role="button" className="nav-link dropdown-bs-toggle" data-bs-toggle="dropdown">Earn<i className="fa fa-caret-down"></i></a>
                                <div className="dropdown-menu">
                                    <Link to="/farms" className='nav-link dropdown-item'>Farms</Link>
                                    <Link to="/liquidity" className='nav-link dropdown-item'>Pools</Link>
                                </div>
                            </li>
                        </ul>
                        <ul className="navbar-nav ms-auto">
                            <Networks></Networks>
                            <ConnectWallet name="menu"></ConnectWallet>
                            <Theme></Theme>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
        )
    }
}

export default AppHeader;
