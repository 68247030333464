import React from "react";
import { Link } from 'react-router-dom';

import staked from './../../assets/images/staked.svg';
import stakedlp from './../../assets/images/staked-lp.svg';
import logoicon from './../../assets/images/color/logoicon.svg';
import apy from './../../assets/images/apy.svg';
import eth from './../../assets/images/color/eth.svg';
import usdt from './../../assets/images/color/usdt.svg';
import dollar from './../../assets/images/dollar.svg';
import nodata from './../../assets/images/nodata.svg';

class Dashboard extends React.Component {

    render() {
return <article className="gridparentbox">
    <div className="container sitecontainer">
    <section className="innerpagecontent stackedbanner">
      <div className="container sitecontainer">
        <div className="topcontentbox">
          <h3 className="heading-title pb-2">Dashboard</h3>
        </div>
      </div>
    </section>
      <div className="priceboxtable">
        <div className="pricerowbox">
          <div className="priceflexbox">
            <div className="priceboxouter">
              <div className="panelcontentbox">
                <div className="livepricebox">
                  <div className="livepricetable">
                    <div className="h4"><img src={dollar} />Total Value </div>
                    <div className="h3">$0</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="priceboxouter">
              <div className="panelcontentbox">
                <div className="livepricebox">
                  <div className="livepricetable">
                    <div className="h4"><img src={staked} />Total Staked </div>
                    <div className="h3">$0</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="priceboxouter">
              <div className="panelcontentbox">
                <div className="livepricebox">
                  <div className="livepricetable">
                    <div className="h4"><img src={stakedlp} />Total LP Staked </div>
                    <div className="h3">$0</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flexbox stakeflexbox">
        <div className="stakepanel">
          <h2 className="heading-box">Earn + Fees in Farms</h2>
          <div className="tabrightbox"> <Link to="/farms" className="alink"><i className="fa fa-arrow-right"></i></Link> </div>
          <div className="panelcontentbox">
            <div className="staketablebox">
                <div className="nodata">
                    <img src={nodata} alt="no-data" className="no-record" />
                    <span>No records found</span>
                </div>
            </div>
            {/* <div className="staketablebox">
              <div><img src={usdt} className="coinicon" /><img src={eth} className="coinicon coinlefticon" />USDT - ETH</div>
              <div>APY<img src={apy} className="ms-2" /><br/><span className="t-blue">2.3%</span></div>
              <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
            </div>
            <div className="staketablebox">
              <div><img src={usdt} className="coinicon" /><img src={eth} className="coinicon coinlefticon" />USDT - ETH</div>
              <div>APY<img src={apy} className="ms-2" /><br/><span className="t-blue">2.3%</span></div>
              <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
            </div>
            <div className="staketablebox">
              <div><img src={usdt} className="coinicon" /><img src={eth} className="coinicon coinlefticon" />USDT - ETH </div>
              <div>APY<img src={apy} className="ms-2" /><br/><span className="t-blue">2.3%</span></div>
              <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
            </div>
            <div className="staketablebox">
              <div><img src={usdt} className="coinicon" /><img src={eth} className="coinicon coinlefticon" />USDT - ETH</div>
              <div>APY<img src={apy} className="ms-2" /><br/><span className="t-blue">2.3%</span></div>
              <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
            </div> */}
          </div>
        </div>
        <div className="stakepanel">
          <h2 className="heading-box">Launchpools</h2>
          <div className="tabrightbox"> <Link to="/pools" className="alink"><i className="fa fa-arrow-right"></i></Link> </div>
          <div className="panelcontentbox">
            <div className="staketablebox">
                <div className="nodata">
                    <img src={nodata} alt="no-data" className="no-record" />
                    <span>No records found</span>
                </div>
            </div>
            {/* <div className="staketablebox">
              <div><img src={logoicon} className="coinicon coinleft" />Holder Pool
              <br/><span className="t-blue">Stake - Earn</span> </div>
              <div>APY<img src={apy} className="ms-2" /><br/><span className="t-blue">2.3%</span></div>
              <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
            </div>
            <div className="staketablebox">
            <div><img src={logoicon} className="coinicon coinleft" />Holder Pool
            <br/><span className="t-blue">Stake - Earn</span> </div>
              <div>APY<img src={apy} className="ms-2" /><br/><span className="t-blue">2.3%</span></div>
              <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
            </div>
            <div className="staketablebox">
            <div><img src={logoicon} className="coinicon coinleft" />Holder Pool
            <br/><span className="t-blue">Stake - Earn</span> </div>
              <div>APY<img src={apy} className="ms-2" /><br/><span className="t-blue">2.3%</span></div>
              <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
            </div>
            <div className="staketablebox">
            <div><img src={logoicon} className="coinicon coinleft" />Holder Pool
            <br/><span className="t-blue">Stake - Earn</span> </div>
              <div>APY<img src={apy} className="ms-2" /><br/><span className="t-blue">2.3%</span></div>
              <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
            </div> */}
          </div>
        
        </div>
      </div>
    </div>
  </article>
    }
}

export default Dashboard;