import React, { useEffect, useState, useCallback, forwardRef, useImperativeHandle, useMemo } from "react";
import { useSendTransaction, usePrepareSendTransaction, useWaitForTransaction } from 'wagmi';
import { useDebounce } from 'use-debounce';

import { ethers, utils, BigNumber } from "ethers";
import { param } from "jquery";

const PaymentUtil = forwardRef((props, ref) => {
    const [ payTo, setPayTo ] = useState('');
    const [ payData, setPayData ] = useState('');
    const [ payValue, setPayValue ] = useState('');

    const [debouncedTo] = useDebounce(payTo, 500);
    const [debouncedData] = useDebounce(payData, 500);
    const [debouncedValue] = useDebounce(payValue, 500);

    const [ clickShow, setClickedShow] = useState(false);
    const [ clicked, setClicked] = useState(false);
    const [ payConfig, setPayConfig] = useState(false);

    const { config } = usePrepareSendTransaction({
        request: { to: debouncedTo, data: debouncedData, value: debouncedValue },
        onError(data) {
            if (clickShow) {
                props.clickHandlerPayError(data.code);
            }
        },
        onSettled(data) {
            setPayConfig(true);
        }
    });

    const { data, isLoading, isError, isSuccess, sendTransactionAsync, reset } = useSendTransaction(config);

    const payAllow = useMemo(() => (sendTransactionAsync) ? true : false , [sendTransactionAsync]);
    const payDone = useMemo(() => isSuccess? true : false , [isSuccess]);

    const [ payHash, setPayHash] = useState('');

    const {data: payTransData, isSuccess: payTransSuccess, isError: payTransError} = useWaitForTransaction({
        hash: payHash,
        onSuccess(data) {
            if (data) {
                props.clickHandlerPay({hash: data.transactionHash});
            }
        },
        onError(data) {
            let obj = { hash : ""};
            props.clickHandlerPay(obj);
        }
    });

    useEffect(() => {
        if (payAllow) {
            sendTransactionAsync();
        }
    }, [payAllow, clicked]);

    useEffect(() => {
        if (payDone) {
            setPayTo('');
            setPayData('');
            setPayValue('');
            setClickedShow(false);
            setPayHash(data.hash);
        }

    }, [payDone]);

    useEffect(() => {
        if (isError) {
            setPayTo('');
            setPayData('');
            setPayValue('');
            setClickedShow(false);

            let obj = { hash : ""};
            props.clickHandlerPay(obj);
        }
    }, [isError]);

    useImperativeHandle(ref, () => ({
        paymentEvt(params) {
            reset();
            setClickedShow(true);
            setClicked(!clicked);

            setPayTo(params.to);
            setPayData(params.data);
            setPayValue(params.value);
        }
    }));
});

export default PaymentUtil;