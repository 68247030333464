import React from "react";
import { Link } from 'react-router-dom';

import ConnectWallet from '../connectwallet/ConnectWallet';
import Theme from '../theme/Theme';
import Networks from '../network/Networks';

import darklogo from './../../assets/images/dark-logo.svg' ;
import logo from './../../assets/images/logo.svg';

import eth from './../../assets/images/color/eth.svg';
import poly from './../../assets/images/color/matic.svg';

class LandingHeader extends React.Component {
    render() {
        return (<header className="headermenu">
            <nav className="navbar navbar-expand-md navbar-dark headbg homeheader">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={darklogo} className="logo"  alt={darklogo} />
                        <img src={logo} className="logo light" />
                    </Link>
                    <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" aria-expanded="false">
                        <span className="toggler-icon top-bar"></span>
                        <span className="toggler-icon middle-bar"></span>
                        <span className="toggler-icon bottom-bar"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item dropdown"><a href="#" role="button" className="nav-link dropdown-bs-toggle" data-bs-toggle="dropdown">Trade<i className="fa fa-caret-down"></i></a>
                                <div className="dropdown-menu">
                                    <Link to="/swap" className='nav-link dropdown-item'>Swap</Link>
                                    <Link to="/liquidity" className='nav-link dropdown-item'>Liquidity</Link>
                                </div>
                            </li>
                            <li className="nav-item dropdown"><a href="#" role="button" className="nav-link dropdown-bs-toggle" data-bs-toggle="dropdown">Earn<i className="fa fa-caret-down"></i></a>
                                <div className="dropdown-menu">
                                    <Link to="/farms" className='nav-link dropdown-item'>Farms</Link>
                                    <Link to="/liquidity" className='nav-link dropdown-item'>Pools</Link>
                                </div>
                            </li>
                            <Networks></Networks>
                            <ConnectWallet name="menu"></ConnectWallet>
                            <Theme></Theme>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
        )
    }
}

export default LandingHeader;
