import React from "react";
import ReactLoading from 'react-loading';
import Modal from 'react-bootstrap/Modal';
import Parse from 'html-react-parser';
import { Link } from 'react-router-dom';

import eth from './../../assets/images/color/eth.svg';
import swap from './../../assets/images/swap.svg';
import droparrow from './../../assets/images/drop-arrow.svg';
import howit from './../../assets/images/how-it-works-img.svg';
import secured from './../..//assets/images/secured-transactions.svg';
import earn from './../../assets/images/earn-passive-income.svg';
import startsec from './../../assets/images/start-in-seconds.svg';
import swapplus from './../../assets/images/swap-plus.svg';
import particalicon from './../../assets/images/particalicon.svg';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ConnectWallet from '../../components/connectwallet/ConnectWallet';
import TokenComponent from '../../components/token/TokenComponent';
import TokenListWithBalanceComponent from '../../components/token/TokenListWithBalanceComponent';

import eventBus from "../../events/EventBus";

import HomeService from "../../services/home/HomeService";
import PaymentUtil from "../../hooks/PaymentUtil";
import ExchangeUtil from "../../hooks/ExchangeUtil";
import SwapUtil from "../../hooks/SwapUtil";

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.exchangeUtilRef = React.createRef();
        this.swapUtilRef = React.createRef();
        this.paymentUtilRef = React.createRef();

        this.service = new HomeService();

        this.state = {
            show: false,
            tokens: [],
            tokensBalance: [],
            isBalLoading: false,

            inputCoinShow: false,
            outputCoinShow: false,
            filteredTxtInput: "",
            filteredTxtOutput: "",
            filteredInputBalance: localStorage.getItem("balance") ? localStorage.getItem("balance") : 0,
            filteredOutputBalance: 0,
            filteredTxtInputPercBal: 0,
            filteredTxtOutputPercBal: 0,
            inputPrice: 0,
            outputPrice: 0,
            isShowInputAlert: false,
            isShowOutputAlert: false,
            showWallet: false,
            priceImpact: 0,
            slippage: 0,
            reserveIn: 0,
            reserveOut: 0,
            isSwap: false,
            tokenprice: null,
            swaptokens: null,
            tokenAllowence: 0,
            isShowInputAllowence: false,
            isShowLiqLimitAlert: false,
            slippagePerc: 0.1,

            liquidityData: null,
            exinputCoinShow: false,
            exoutputCoinShow: false,
            exfilteredTxtInput: "",
            exfilteredTxtOutput: "",
            exfilteredInputBalance: localStorage.getItem("balance") ? localStorage.getItem("balance") : 0,
            exfilteredTxtLiqtyInputContract: process.env.REACT_APP_ETH,
            exfilteredTxtLiqtyOutputContract: "",
            exfilteredOutputBalance: 0,
            exfilteredTxtInputPercBal: 0,
            exfilteredTxtOutputPercBal: 0,
            exinputPrice: 0,
            exoutputPrice: 0,
            exisShowInputAlert: false,
            exisShowOutputAlert: false,
            exisShowInputAllowence: false,
            exisShowOutputAllowence: false,
            inputAmt: 0,
            outputAmt: 0,
            total: 0,
            isShowAddLiq: false,
            approveFilterType: "",
            isAddLiqBtn: false,
            liquidityList: [],
            ispairloading: false,
            isShowLiqPair: false,
            filteredPairData: {},
            liqInBal: 0,
            liqOutBal: 0,
            pairData: {},
            poolShare: 0,
            receiveTokens: 0,
            rmLiqAllowence: false,
            isFirstLiq: false,
            // slippagePerc: 0.1,
            cms: {
                home: {
                    title: "",
                    content: ""
                },
                features: [],
                howItWorks: [],
                aboutus: "",
                faqs: []
            },
            faqs: [],
            faqSeeMore: false,
            deadline: 20,
            isPayPopup: false,
            isPaid: false,
            payAmt: 0,
            payLoading: false,
            pairAlert: ''
        };

        this.showInputCoin = this.showInputCoin.bind(this);
        this.showOutputCoin = this.showOutputCoin.bind(this);

        this.exshowInputCoin = this.exshowInputCoin.bind(this);
        this.exshowOutputCoin = this.exshowOutputCoin.bind(this);

        this.examtCalInputEvent = this.examtCalInputEvent.bind(this);
        this.examtCalOutputEvent = this.examtCalOutputEvent.bind(this);
    }

    componentDidMount() {
        // this.getToken();
        this.getHomeCms();
        this.getTokensBalance();
        this.checkPay();

        if (this.service.address()) {
            this.getTokensBalance();
            this.getLiquidityList();
        }

        eventBus.on("connectWallet", (data) => {
            this.setState({filteredTxtInput: localStorage.getItem("token")});

            if (data.status && this.service.address()) {
                this.checkPay();
                this.getTokensBalance();
                this.setState({filteredTxtInput: localStorage.getItem("token"), filteredInputBalance: localStorage.getItem("balance"), exfilteredInputBalance: localStorage.getItem("balance"), inputAmt: localStorage.getItem("balance")});
            }
        });

        eventBus.on("disconnectWallet", (data) => {
            localStorage.clear();
            this.service.address();

            this.setState({filteredInputBalance: 0}, () =>  {
                this.getTokensBalance();
            });
        });

        eventBus.on("networkchange", (data) => {
            this.checkPay();
            this.getTokensBalance();
            this.getLiquidityList();
        });
    }

    getToken() {
        this.setState({isLiqLoading: true});

        this.service.getTokens().then(res => {
            if (res.success) {
                this.setState({tokens: res.data, isLiqLoading: false});
            }
        });
    }

    checkPay() {
        if (!this.service.isPay()) {
            var params = {
                address: this.service.address(),
                network: this.service.network()
            };

            this.service.getPaymentStatus(params).then(res => {
                if (res.success) {
                    this.setState({
                        isPaid: (res.data.pay_status === "true") ? true : false,
                        payAmt: res.data.pay_amount,
                        isPayPopup: (res.data.pay_status === "true") ? false : true
                    });
                }
            });
        } else {
            this.setState({isPayPopup: false});
        }
    }

    payPopupAction = () => {
        this.setState({ isPayPopup: !this.state.isPayPopup });
    }

    getHomeCms() {
        this.service.getHomeCms().then(res => {
            if (res.data) {
                this.setState({
                    cms: {
                        home: {
                            title: res.data.home.title,
                            content: res.data.home.content
                        },
                        features: res.data.features,
                        howItWorks: res.data.howItWorks,
                        aboutus: res.data.aboutus,
                        faqs: res.data.faqs
                    },
                    faqs: res.data.faqs,
                    faqSeeMore: res.data.fagmore
                });
            }
        });
    }

    getTokensBalance() {
        var params = {
            address: this.service.address(),
            avail: this.state.filteredInputBalance,
            network: this.service.network()
        };

        this.setState({ isBalLoading : true, isLiqLoading: true });

        this.service.getAllTokenBalance(params).then(res => {
            if (res.success) {
                this.setState({tokensBalance: res.data, isBalLoading: false, isLiqLoading: false }, () => {
                    this.initialLiquidity();
                });
            }
        });
    }

    initialLiquidity() {
        let inputTxt = "";

        if (this.state.tokensBalance.length > 0) {
            inputTxt = this.state.tokensBalance[0]['slug'];
        }

        if (inputTxt) {
            let input = this.filteredBalance(inputTxt);
            this.setState({filteredTxtInput: inputTxt, exfilteredTxtInput: inputTxt, filteredInputBalance: input });
        }

        let fromContract = this.filteredContract(inputTxt);
        let fromBalance = this.filteredBalance(inputTxt);

        if (fromContract) {
            this.setState({exfilteredTxtLiqtyInputContract: fromContract, exfilteredInputBalance: fromBalance});
        }
    }

    filteredContract(name) {
        let contract = "";

        var filterInfo = this.state.tokensBalance.find((tokenInfo) => {
            return tokenInfo.slug == name;
        });

        if (filterInfo) {
            contract = filterInfo.contract_address;
        }

        return contract;
    }

    filteredBalance(name) {
        let balance = 0;

        var filterInfo = this.state.tokensBalance.find((tokenInfo) => {
            return tokenInfo.slug == name;
        });

        if (filterInfo) {
            balance = filterInfo.balance;
        }

        return balance;
    }

    showInputCoin = () => {
        if (this.state.isPaid) {
            this.setState(prevState => ({
                inputCoinShow: !prevState.inputCoinShow
            }));
        } else {
            this.setState({ isPayPopup: true });
        }
    }

    exshowInputCoin = () => {
        if (this.state.isPaid) {
          this.setState(prevState => ({
              exinputCoinShow: !prevState.exinputCoinShow
          }));
        } else {
          this.setState({ isPayPopup: true });
        }
    }

    showOutputCoin = () => {
        if (this.state.isPaid) {
          this.setState(prevState => ({
              outputCoinShow: !prevState.outputCoinShow
          }));
        } else {
          this.setState({ isPayPopup: true });
        }
    }

    exshowOutputCoin = () => {
        if (this.state.isPaid) {
          this.setState(prevState => ({
              exoutputCoinShow: !prevState.exoutputCoinShow
          }));
        } else {
          this.setState({ isPayPopup: true });
        }
    }

    filteredImageLink = (name, type, imgname=true) => {
        var path = "";

        if (name) {
            this.state.tokensBalance.filter(token => {
                if (token.slug === name) {
                    path = token.logo_url;
                }
            });
        }

        if (path) {
            return <><img src={path} className='coinicon' alt="{ imgname ? name : '' }"/> { imgname ? name : '' } </>;
        } else {
            if (type === "input") {
                return <><img src={eth} className='coinicon' alt="{name}" /> { name } </>;
            } else {
                return <>Select</>
            }
        }
    }

    filteredItemEvent = (name, balance, type, usd) => {
        if (type === "input") {
            this.setState({ filteredTxtInput: name, filteredInputBalance: balance, filteredTxtInputPercBal: 0, isShowInputAlert: false, isShowOutputAlert: false });

            this.showInputCoin();

            if (name && this.service.address()) {
                this.getPairAllowence(name);
            }

            if (name && this.state.filteredTxtOutput && this.service.address()) {
                this.getTokenPrice(name, this.state.filteredTxtOutput);
            }
        } else {
            this.setState({ filteredTxtOutput: name, filteredOutputBalance: balance, filteredTxtOutputPercBal: 0, isShowInputAlert: false, isShowOutputAlert: false });

            this.showOutputCoin();

            if (name && this.state.filteredTxtInput && this.service.address()) {
                this.getTokenPrice(this.state.filteredTxtInput, name);
            }
        }
    }

    exfilteredItemEvent = (name, balance, type, usd) => {
        if (type === "input") {
            this.setState({ exfilteredTxtInput: name, exfilteredInputBalance: balance, exfilteredTxtInputPercBal: 0, exisShowInputAlert: false, exisShowOutputAlert: false });

            this.exshowInputCoin();

            if (name && this.service.address()) {
                this.getPairAllowence(name);
            }

            if (name && this.state.exfilteredTxtOutput && this.service.address()) {
                this.exgetTokenPrice(name, this.state.exfilteredTxtOutput);
            }
        } else {
            this.setState({ exfilteredTxtOutput: name, exfilteredOutputBalance: balance, exfilteredTxtOutputPercBal: 0, exisShowInputAlert: false, exisShowOutputAlert: false });

            this.exshowOutputCoin();

            if (name && this.state.exfilteredTxtInput && this.service.address()) {
                this.exgetTokenPrice(this.state.exfilteredTxtInput, name);
            }
        }
    }

    getTokenPrice = (input, output) => {
        var params = {
            input_slug: input,
            output_slug: output,
            network: this.service.network()
        };

        this.setState({isBalLoading: true, pairAlert: ''});

        this.service.getTokensPrice(params).then(res => {
            if (res.success) {
                this.setState({tokenprice: res.data, isBalLoading: false});

                var params = {
                    inputPrice: 1,
                    outputPrice: res.data.inputTokenMidPrice,
                    priceImpact: res.data.priceImpact,
                    slippage: res.data.slippage,
                    reserveIn: res.data.reserveIn,
                    reserveOut: res.data.reserveOut
                };

                if (res.data.error) {
                    params.pairAlert = res.data.error;
                }

                this.setState(params);
            }
        });
    }

    exgetTokenPrice = (input, output) => {
        var params = {
            input_slug: input,
            output_slug: output,
            network: this.service.network()
        };

        this.setState({isBalLoading: true});

        this.service.getTokensPrice(params).then(res => {
            if (res.success) {
                this.setState({tokenprice: res.data, isBalLoading: false});

                var params = {
                    inputPrice: 1,
                    outputPrice: res.data.inputTokenMidPrice,
                    priceImpact: res.data.priceImpact,
                    slippage: res.data.slippage,
                    reserveIn: res.data.reserveIn,
                    reserveOut: res.data.reserveOut
                };

                this.setState(params);
            }
        });
    }

    getPairAllowence = (input, output) => {
        var params = {
            input_slug: input,
            from_address: this.service.address(),
            network: this.service.network()
        };

        this.setState({isBalLoading: true});

        this.service.getTokensAllowence(params).then(res => {
            if (res.success) {
                if (res.data) {
                    this.setState({tokenAllowence: res.data.allowence, isBalLoading: false});
                }
            }
        });
    }

    amtCalInputEvent = (event) => {
        var inputVal = event.target.value.replace(/[^0-9\.]/g,'');

        if (inputVal.split('.').length > 2) {
            return false;
        }

        this.setState({ filteredTxtInputPercBal: inputVal});
        this.customizeInputEvent(inputVal);
    }

    customizeInputEvent = (inputVal) => {
        var isShowInputAlert = false;
        var isShowOutputAlert = false;
        var isShowInputAllowence = false;

        this.inputPriceCal(inputVal);

        if (parseFloat(inputVal) > parseFloat(this.state.filteredInputBalance)) {
            isShowInputAlert = true;
        }

        if (inputVal > parseFloat(this.state.tokenAllowence) && !isShowInputAlert) {
            isShowInputAllowence = true;
        }

        if (this.state.filteredTxtInput === "ETH" || this.state.filteredTxtInput === "MATIC") {
            isShowInputAllowence = false;
        }

        var params = {
            filteredTxtInputPercBal: inputVal,
            filteredTxtOutputPercBal: this.outPercBal,
            isShowInputAlert: isShowInputAlert,
            isShowOutputAlert: isShowOutputAlert,
            isShowInputAllowence: isShowInputAllowence
        };

        this.setState(params);
    }

    inputPriceCal = (input) => {
        var isShowInputAllowence = false;
        var amountInWithFee = parseFloat(input) * 997;
        var numerator = parseFloat(amountInWithFee) * parseFloat(this.state.reserveOut);
        var denominator = (parseFloat(this.state.reserveIn) * 1000) + parseFloat(amountInWithFee);

        var output = parseFloat(numerator) / parseFloat(denominator);

        output = output - (output * (this.state.slippagePerc / 100));

        if (output === Infinity || isNaN(output)) {
            output = 0;
        }

        this.outPercBal = (output > 0) ? output.toFixed(6) : 0;
    }

    outputPriceCal = (output) => {
        var isShowInputAllowence = false;
        var isShowInputAlert = false;
        var numerator = ( parseFloat(this.state.reserveIn) * parseFloat(output) ) * 1000;
        var denominator = (parseFloat(this.state.reserveOut) - parseFloat(output)) * 997;
        var input = ((parseFloat(numerator) / parseFloat(denominator)) * 10 **(18)) + 1;

        input = input * 10 ** (-18);

        if (input === Infinity || isNaN(input)) {
            input = 0;
        }

        this.inPercBal = (input > 0) ? this.formatData(input, 6) : 0;
    }

    amtCalOutputEvent = (event) => {
        if (this.state.filteredTxtOutput) {
            var outputVal = event.target.value.replace(/[^0-9\.]/g,'');

            if (outputVal.split('.').length > 2) {
                return false;
            }

            this.outputPriceCal(outputVal);
            this.setState({ filteredTxtOutputPercBal: outputVal});

            var isShowInputAlert = false;
            var isShowOutputAlert = false;
            var isShowInputAllowence = false;
            var isShowLiqLimitAlert = false;

            if (parseFloat(this.inPercBal) > parseFloat(this.state.filteredInputBalance)) {
                isShowInputAlert = true;
            }

            if (this.inPercBal > parseFloat(this.state.tokenAllowence) && !isShowInputAlert) {
                isShowInputAllowence = true;
            }

            if (parseFloat(outputVal) > parseFloat(this.state.filteredOutputBalance)) {
                isShowOutputAlert = true;
                isShowLiqLimitAlert = false;
            }

            if (!isShowOutputAlert && parseFloat(this.inPercBal) < 0) {
                isShowLiqLimitAlert = true;
            }

            if (this.state.filteredTxtInput === "ETH" || this.state.filteredTxtInput === "MATIC") {
                isShowInputAllowence = false;
            }

            this.setState({isShowOutputAlert: isShowOutputAlert, isShowInputAlert: isShowInputAlert, isShowInputAllowence: isShowInputAllowence, filteredTxtInputPercBal: Math.abs(this.inPercBal), isShowLiqLimitAlert: isShowLiqLimitAlert});
        }
    }

    spenderApproveEvent = () => {
        var params = {
            input_slug: this.state.filteredTxtInput,
            from_address: this.service.address(),
            amount: this.state.filteredTxtInputPercBal,
            network: this.service.network()
        };

        this.service.spenderApprove(params).then(res => {
            if (res.data) {
                this.spenderApprove(res.data);
            }
        });
    }

    spenderApprove(data) {
        const transactionParameters = {
            to: data.contract,
            from: this.service.address(),
            value: '0x0',
            data: data.data.data
        };

        this.swapUtilRef.current.spenderApprove(transactionParameters);
    }

    spenderApproveConfirmed = (data) => {
        if (data.hash) {
            toast.success(this.state.filteredTxtInput + " approved successfully");

            this.setState({isShowInputAllowence: false});
        } else {
            toast.error("Transaction Cancelled");
        }
    }

    swapConfirmationEvent = () => {
        var params = {
            input_slug: this.state.filteredTxtInput,
            output_slug: this.state.filteredTxtOutput,
            input_amt: this.state.filteredTxtInputPercBal,
            output_amt: this.state.filteredTxtOutputPercBal,
            from_address: this.service.address(),
            deadline: this.state.deadline,
            network: this.service.network()
        };

        this.service.swapConfirmation(params).then(res => {
            if (res.data) {
                this.setState({isSwap: true});
                this.swapConfirmation(res.data);
            }
        });
    }

    swapConfirmation(data) {
        const transactionParameters = {
            to: data.to,
            from: this.service.address(),
            value: data.value,
            data: data.data
        };

        this.swapUtilRef.current.swapConfirm(transactionParameters);
    }

    swapConfirmed = (data) => {
        if (data.hash) {
            toast.success("Swap Completed Successfully");

            this.setState({isSwap: false, filteredTxtInputPercBal:0, filteredTxtOutputPercBal:0});
        } else {
            toast.error("Swap Cancelled");

            this.setState({isSwap: false, filteredTxtInputPercBal:0, filteredTxtOutputPercBal:0});
        }
    }

    swapConfirmationCancelEvent = () => {
        this.setState({isSwap: false, filteredTxtInputPercBal:0, filteredTxtOutputPercBal:0});
    }

    showConfirmSection = () => {
        return (
            this.service.address() &&
            this.state.filteredTxtInputPercBal &&
            this.state.filteredTxtOutputPercBal &&
            !this.state.isShowInputAlert &&
            !this.state.isShowOutputAlert &&
            !this.state.isShowLiqLimitAlert
        ) ? true : false;
    }

    formatData(data, decimal) {
        let value = parseFloat(data);

        return (value > 0) ? value.toFixed(decimal) : value;
    }

    showLiquidityBtn = () => {
        return (!this.state.exisShowInputAllowence &&
        !this.state.exisShowOutputAllowence &&
        (parseFloat(this.state.inputAmt) > 0) &&
        (parseFloat(this.state.outputAmt) > 0) &&
        !this.state.exisShowInputAlert &&
        !this.state.exisShowOutputAlert) ? true : false;
    }

    examtCalInputEvent = (event) => {
        var inAmt = event.target.value.replace(/[^0-9\.]/g,'');

        if (inAmt.split('.').length > 2) {
            return false;
        }

        if (this.state.exfilteredTxtInput && this.state.exfilteredTxtOutput) {
            var params = {
                inputVal: inAmt,
                inputOrg: this.state.exfilteredInputBalance,
                outputOrg: this.state.exfilteredOutputBalance,
                token2: this.state.liquidityData ? this.state.liquidityData.result.token2price : 0,
                token1allowance: this.state.liquidityData ? this.state.liquidityData.result.token1allowance : 0,
                token2allowance: this.state.liquidityData ? this.state.liquidityData.result.token2allowance : 0,
                isFirstL: this.state.isFirstLiq
            };

            this.service.examtCalInputEvent(params).then(res => {
                this.setState(res);
                this.quoteAddLiquidity(res.inputAmt, res.outputAmt);
            });
        } else {
            // toast.error("To Token Must be selected");
        }
    }

    examtCalOutputEvent = (event) => {
        var outputVal = event.target.value.replace(/[^0-9\.]/g,'');

        if (outputVal.split('.').length > 2) {
            return false;
        }

        var params = {
            inputOrg: this.state.exfilteredInputBalance,
            outputVal: outputVal,
            outputOrg: this.state.exfilteredOutputBalance,
            token1: this.state.liquidityData ? this.state.liquidityData.result.token1price : 0,
            token2allowance: this.state.liquidityData ? this.state.liquidityData.result.token2allowance : 0,
            isFirstL: this.state.isFirstLiq
        }

        this.service.examtCalOutputEvent(params).then(res => {
            this.setState(res);
            this.quoteAddLiquidity(res.inputAmt, res.outputAmt);
        });
    }

    quote(amount1, reserve1, reserve2) {
        var amount2 = amount1 * (reserve2 / reserve1);

        return amount2;
    }

    quoteAddLiquidity(amountADesired, amountBDesired) {
        if (this.state.liquidityData.result.reserveA === 0 && this.state.liquidityData.result.reserveB === 0) {
            var amountOut = this.quoteMintLiquidity(amountADesired, amountBDesired);

            this.setState({receiveTokens: amountOut});
            this.sharePoolCal(amountOut);
        } else {
            const amountBOptimal = this.quote(amountADesired, this.state.liquidityData.result.reserveA, this.state.liquidityData.result.reserveB);

            if (amountBOptimal <= amountBDesired) {
                const amountOut = this.quoteMintLiquidity(amountADesired, amountBOptimal);

                this.setState({receiveTokens: amountOut});
                this.sharePoolCal(amountOut);
            } else {
                const amountAOptimal = this.quote(amountBDesired,this.state.liquidityData.result.reserveB,this.state.liquidityData.result.reserveA);
                const amountOut = this.quoteMintLiquidity(amountAOptimal, amountBDesired);

                this.setState({receiveTokens: amountOut});
                this.sharePoolCal(amountOut);
            }
        }
    }

    quoteMintLiquidity(amountA, amountB){
        const MINIMUM_LIQUIDITY = 1000;
        const valueA = amountA*(10**this.state.liquidityData.result.token1Decimals);
        const valueB = amountB*(10**this.state.liquidityData.result.token2Decimals);
        const reserve_a = this.state.liquidityData.result.reserveA *(10**this.state.liquidityData.result.token1Decimals);
        const reserve_b = this.state.liquidityData.result.reserveB*(10**this.state.liquidityData.result.token2Decimals);

        if (this.state.liquidityData.result.totalSupply === 0){
            return Math.sqrt(((reserve_a * reserve_b)-MINIMUM_LIQUIDITY))*10**(-18);
        };

        return Math.min(valueA*this.state.liquidityData.result.totalSupply/reserve_a, valueB*this.state.liquidityData.result.totalSupply/reserve_b);
    }

    sharePoolCal(receiveTokens) {
        var total = receiveTokens + this.state.liquidityData.result.totalSupply;
        var perc = (receiveTokens / total) * 100;

        this.setState({poolShare: perc});
    }

    maxLimitEvet = (type) => {
        var inAmt = 0;
        var outAmt = 0;
        var params = {};

        if (type === "input") {
            inAmt = this.formatData(this.state.filteredInputBalance, 6);
            outAmt = this.formatData(this.state.filteredOutputBalance, 6);

            params = {
                inputVal: inAmt,
                outputVal: outAmt,
                token2: this.state.liquidityData ? this.state.liquidityData.result.token2price : 0,
                token1allowance: this.state.liquidityData ? this.state.liquidityData.result.token1allowance : 0,
                token2allowance: this.state.liquidityData ? this.state.liquidityData.result.token2allowance : 0
            }

            this.service.amtCalInputEvent(params).then(res => {
                this.setState(res);
            });
        } else {
            inAmt = this.formatData(this.state.filteredInputBalance, 6);
            outAmt = this.formatData(this.state.filteredOutputBalance, 6);

            params = {
                inputVal: inAmt,
                outputVal: outAmt,
                token1: this.state.liquidityData ? this.state.liquidityData.result.token1price : 0,
                token2allowance: this.state.liquidityData ? this.state.liquidityData.result.token2allowance : 0,
            }

            this.service.amtCalOutputEvent(params).then(res => {
                this.setState(res);
            });
        }
    }

    getLiquidityList() {
        var params = {
            address: this.service.address(),
            network: this.service.network()
        };

        this.setState({ispairloading: true});

        this.service.getLiquidityList(params).then(res => {
            if (res && res.data && res.data.data) {
                this.setState({liquidityList: res.data.data, ispairloading: false});
            }
        });
    }

    filterLiquidity = (name, contractAddress, type, balance) => {
        let balanceAmt = (balance == undefined) ? 0 :  balance;

        if (type === "input") {
            this.setState({
                exfilteredTxtLiqtyInput: name,
                exfilteredInputBalance: balanceAmt,
                exfilteredTxtLiqtyInputContract: contractAddress,
                exfilteredTxtInput: name,
                exisShowInputAlert: false,
                exisShowOutputAlert: false,
                inputAmt: 0,
                outputAmt: 0,
                exisShowInputAllowence: false,
                exisShowOutputAllowence: false
            });

            this.showLiqInputCoin();

            if (contractAddress && this.state.exfilteredTxtLiqtyOutputContract) {
                this.checkLiquidity(contractAddress, this.state.exfilteredTxtLiqtyOutputContract);
            }
        } else {
            this.setState({
                exfilteredTxtLiqtyOutput: name,
                exfilteredOutputBalance: balanceAmt,
                exfilteredTxtLiqtyOutputContract: contractAddress,
                exfilteredTxtOutput: name,
                exisShowInputAlert: false,
                exisShowOutputAlert: false,
                inputAmt: 0,
                outputAmt: 0,
                exisShowInputAllowence: false,
                exisShowOutputAllowence: false
            });

            this.showLiqOutputCoin();

            if (this.state.exfilteredTxtLiqtyInputContract && contractAddress) {
                this.checkLiquidity(this.state.exfilteredTxtLiqtyInputContract, contractAddress);
            }
        }
    }

    showLiqInputCoin = () => {
        this.setState(prevState => ({
            exinputCoinShow: !prevState.exinputCoinShow
        }));
    }

    showLiqOutputCoin = () => {
        this.setState(prevState => ({
            exoutputCoinShow: !prevState.exoutputCoinShow
        }));
    }

    checkLiquidity = (input, output) => {
        if (this.service.address()) {
            var params = {
                address_input: input,
                address_output: output,
                from_address: this.service.address(),
                network: this.service.network()
            };

            this.setState({isLiqLoading: true, exisShowInputAlert: false, exisShowOutputAlert: false});

            this.service.checkLiquidity(params).then(res => {
                if (res.success) {
                    if (res.data && res.data.result) {
                        let isFirstL = (res.data.error) ? true : false;
                        this.setState({liquidityData: res.data, isFirstLiq: isFirstL, isLiqLoading: false});
                        this.service.setLiquidity(res.data.result);
                    } else {
                        this.setState({liqAlertShow: true, isShowAddLiq: true, isLiqLoading: false});
                    }
                }
            });
        }
    }

    filteredLiquidityPair = (data) => {
        this.setState({isShowLiqPair: true, filteredPairData: data, liqAmt: data.balance});

        var params = {
            amt : data.balance,
            input_address: data.token0_address,
            ouput_address: data.token1_address,
            pair_address: data.pairAddress,
            network: this.service.network()
        };

        this.service.getLiquidityPairData(params).then(res => {
            if (res && res.data) {
                this.setState({
                    liqInBal: res.data.token0Out,
                    liqOutBal: res.data.token1Out,
                    pairData: res.data
                });
            }
        });
    }

    showHideLiqPair = () => {
        this.setState({ isShowLiqPair: !this.state.isShowLiqPair });
    }

    approveTokenEvent = (type) => {
        var params = {
            slug: (type === "input") ? this.state.exfilteredTxtInput : this.state.exfilteredTxtOutput,
            amount: (type === "input") ? this.state.inputAmt : this.state.outputAmt,
            from_address: this.service.address(),
            network: this.service.network()
        };

        this.service.approveToken(params).then(res => {
            if (res.success) {
                var params = { approveFilterType: type };

                this.setState(params);
                this.tokenApproveProvider(res.data);
            }
        });
    }

    tokenApproveProvider(data) {
        this.exchangeUtilRef.current.tokenApprove(data);
    }

    tokenApproveConfirmed = (data) => {
        if (data.hash) {
            if (this.state.approveFilterType === "input") {
                toast.success(this.state.exfilteredTxtInput + " approved successfully");

                this.setState({exisShowInputAllowence: false});

            } else {
                toast.success(this.state.exfilteredTxtOutput + " approved successfully");

                this.setState({exisShowOutputAllowence: false});
            }
        } else {
            toast.error("Approve Cancelled");
        }
    }

    addLiquidity = () => {
        var params = {
            input_slug: this.state.exfilteredTxtInput,
            output_slug: this.state.exfilteredTxtOutput,
            input_amt: this.state.inputAmt.toString(),
            output_amt: this.state.outputAmt.toString(),
            from_address: this.service.address(),
            network: this.service.network()
        };

        this.service.addLiquidity(params).then(res => {
            if (res.data) {
                this.addLiquidityProvider(res.data);
            }
        });
    }

    addLiquidityProvider(data) {
        this.exchangeUtilRef.current.addLiquidity(data);
    }

    addLiquidityConfirmed = (data) => {
        if (data.hash) {
            toast.success("Tokens added to liquidity");
            this.setState({isShowAddLiq: false, isEnableLiqView: false, isLiqLoading: false, inputAmt : 0, outputAmt: 0});
        } else {
            toast.error("Transaction Cancelled");
        }
    }

    payEvent = (event) => {
        if (this.state.filteredInputBalance > this.state.payAmt) {
            let params = {
                address: this.service.address(),
                amount: this.state.payAmt,
                network: this.service.network()
            };

            this.setState({payLoading: true});

            this.service.paymentConfirmation(params).then(res => {
                if (res.data) {
                this.paymentProccessEvt(res.data);
                }
            });
        } else {
            toast.error("Insufficient fund in your wallet");
        }
    }

    paymentProccessEvt(data) {
        const transactionParameters = {
            to: data.to,
            from: this.service.address(),
            value: data.value,
            data: data.data
        };

        this.paymentUtilRef.current.paymentEvt(transactionParameters);
    }

    paymentProccess = (data) => {
        if (data.hash) {
            this.setState({ isPayPopup: false, payLoading: false, isPaid: true });
            toast.success("Payment is successfully!.");

            this.service.updatePayment({address: this.service.address(), amount: this.state.payAmt, txid: data.hash, network: this.service.network()});
        } else {
            toast.error("Payment Cancelled");

            this.setState({payLoading: true});
        }
    }

    payWarningMessage = (data) => {
        toast.error(data);

        this.setState({payLoading: false});
    }

    warningMessage = (data) => {
        toast.error(data);
    }

    render() {
        document.body.classList.add('home');

        return <div>
        <section className="homebannerbg">
            <div className="container sitebannercontent">
                <div className="row table-content">
                    <div className="col-md-6 homeopt">
                        <h3 className="h3">{ this.state.cms.home.title }</h3>
                        <h5 className="h5">{ this.state.cms.home.content }</h5>

                        { !this.service.address() && <ConnectWallet name="menu"></ConnectWallet> }
                    </div>
                    <div className="col-md-6 text-center">
                        <div className="wallet-frm">
                            <h4 className="h4">SWAP</h4>
                            <form className="siteformbox">
                                {
                                    this.state.isShowInputAlert &&
                                    <div className="alert-org-color alert alert-danger"><i className="fa fa-info-circle"></i> Insufficient {this.state.filteredTxtInput} Balance </div>
                                }
                                <div className={`form-group cryptobg ${ this.state.isBalLoading ? 'common-loading pe-none' : '' } `}>
                                    <div className="input-group labelinput">
                                        <span className="input-group-text">
                                            <label className="labelbox">
                                                From
                                            </label>
                                        </span>
                                        <span className="pull-right text-right">
                                            <span>Balance : { this.formatData(this.state.filteredInputBalance, 6) }</span>
                                        </span>
                                    </div>
                                    <div className="input-group">
                                        <input className="form-control form-control-lg" disabled={this.state.isBalLoading} value={ this.state.filteredTxtInputPercBal } onChange={this.amtCalInputEvent} placeholder={this.state.filteredTxtInputPercBal} />
                                        <span className="input-group-text">
                                            <div className="convertbox">
                                                <a onClick={this.showInputCoin}>
                                                    { this.filteredImageLink(this.state.filteredTxtInput, 'input') }
                                                    <i><img src={droparrow} alt={droparrow} /></i>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group text-center">
                                    { this.state.isBalLoading ?
                                        <ReactLoading type="bars" color="#3d85c6" height={'6%'} width={'6%'} className="flex flex-wrap content-center justify-center w-5 h-5 bg-blue w-5 ma2 h4 items-center justify-center flex flex-column flex-wrap mx-auto" />
                                        :
                                        <i><img src={swap}  alt={swap} /></i>
                                    }
                                </div>

                                <div className={`form-group cryptobg ${ this.state.isBalLoading ? 'common-loading pe-none' : '' } `}>
                                    <div className="input-group labelinput"> <span className="input-group-text">
                                            <label className="labelbox">
                                                To
                                            </label>
                                        </span> <span><span>Balance : { this.formatData(this.state.filteredOutputBalance, 6) }</span></span>
                                    </div>
                                    <div className="input-group">
                                        <input className="form-control form-control-lg" disabled={this.state.isBalLoading} value={ this.state.filteredTxtOutputPercBal } onChange={this.amtCalOutputEvent} placeholder={this.state.filteredTxtOutputPercBal} />
                                        <span className="input-group-text">
                                            <div className="convertbox">
                                                <a onClick={this.showOutputCoin}>
                                                    { this.filteredImageLink(this.state.filteredTxtOutput, 'output') }
                                                    <i><img src={droparrow} alt={droparrow} /></i>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>

                                { (this.state.pairAlert.length > 0) &&
                                    <div className="alert-org-color alert alert-danger"><i className="fa fa-info-circle"></i> { this.state.pairAlert } </div>
                                }

                                {
                                    this.showConfirmSection() &&

                                    <div className="form-group text-center btncenterbox">
                                        { (this.state.isShowInputAllowence) ?
                                            <button type="button" className="btn sitebtn btn-block" onClick={ () => this.spenderApproveEvent() }>Approve { this.state.filteredTxtInput } </button>
                                            :
                                            <>
                                                <button type="button" className="btn border-btn" onClick={ ()=> this.swapConfirmationCancelEvent() }>Cancel</button>
                                                <button type="button" className="btn sitebtn" id="confirmwallet" onClick={ () => this.swapConfirmationEvent() }>Confirm</button>
                                            </>
                                        }
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="features">
            <div className="container">
                <h1 className="heading-title">Features</h1>
                <div className="f-flex">
                    <div className="f-box">
                        <img src={secured} className="f-icon" alt={secured} />
                        <h5 className="h5">{ (this.state.cms.features.length > 0) && this.state.cms.features[0].title_1} </h5>
                        <p className="content">{ (this.state.cms.features.length > 0) && this.state.cms.features[0].content_1}</p>
                    </div>
                    <div className="f-box">
                        <img src={earn} className="f-icon" alt={earn} />
                        <h5 className="h5">{ (this.state.cms.features.length > 1) && this.state.cms.features[1].title_2}</h5>
                        <p className="content">{ (this.state.cms.features.length > 1) && this.state.cms.features[1].content_2}</p>
                    </div>
                    <div className="f-box">
                        <img src={startsec} className="f-icon" alt={startsec} />
                        <h5 className="h5">{ (this.state.cms.features.length > 2) && this.state.cms.features[2].title_3}</h5>
                        <p className="content">{ (this.state.cms.features.length > 2) && this.state.cms.features[2].content_3}</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="viewbg">
            <div className="partcal-img top">
               <img src={particalicon} />
            </div>
            <div className="container">
                <div className="v-flex">
                    <div className="v-box">
                        <h4 className="h4">Decentralization is the future!</h4>
                    </div>
                </div>
            </div>
            <div className="partcal-img bottom">
                 <img src={particalicon} alt={particalicon} />
            </div>
        </section>
        <section className="how-bg">
            <div className="container">
                <div className="row table-content">
                    <div className="col-md-6">
                        <div className="how-img">
                            <img src={howit} className="how-caret" alt={howit} />

                            <div className="add-form">
                                <h4 className="h4">Add Liquidity</h4>
                                <div className="siteformbox">
                                    {
                                        this.state.exisShowInputAlert &&
                                        <div className="alert-org-color alert alert-danger"><i className="fa fa-info-circle"></i> Insufficient {this.state.exfilteredTxtInput} Balance </div>
                                    }

                                    <div className={`form-group cryptobg ${this.state.isLiqLoading ? 'common-loading pe-none' : ''} `}>
                                        <div className="input-group labelinput">
                                            <span className="input-group-text">
                                                <label className="labelbox">
                                                    Choose token
                                                </label>
                                            </span>
                                            <span className="pull-right text-right">
                                                <span>MAX : { this.formatData(this.state.exfilteredInputBalance, 6) }</span>
                                            </span>
                                        </div>
                                        <div className="input-group"> <span className="input-group-text">
                                                <div className="convertbox">
                                                    <a onClick={this.exshowInputCoin}>
                                                        {this.filteredImageLink(this.state.exfilteredTxtInput, 'input')}
                                                        <i><img src={droparrow} alt={droparrow} /></i>
                                                    </a>
                                                </div>
                                            </span>
                                            <input type="text" className="form-control form-control-lg" value={this.state.inputAmt} onChange={this.examtCalInputEvent} pattern="[+-]?\d+(?:[.,]\d+)?" />
                                        </div>
                                    </div>
                                    <div className="form-group text-center">
                                        {
                                            this.state.isLiqLoading ?
                                                <ReactLoading
                                                    type="bars"
                                                    color="#3d85c6"
                                                    height={'6%'}
                                                    width={'6%'}
                                                    className="flex flex-wrap content-center justify-center w-5 h-5 bg-blue w-5 ma2 h4 items-center justify-center flex flex-column flex-wrap mx-auto"
                                                />
                                            :
                                            <i><img src={swapplus}  alt={swapplus}/></i>
                                        }
                                    </div>

                                    {
                                        this.state.exisShowOutputAlert &&
                                        <div className="alert-org-color alert alert-danger"><i className="fa fa-info-circle"></i> Insufficient {this.state.exfilteredTxtOutput} Balance </div>
                                    }

                                    <div className={`form-group cryptobg ${this.state.isLiqLoading ? 'common-loading pe-none' : ''} `}>
                                        <div className="input-group labelinput"> <span className="input-group-text">
                                                <label className="labelbox">
                                                    Choose token
                                                </label>
                                            </span><span><span></span></span>
                                        </div>
                                        <div className="input-group"> <span className="input-group-text">
                                                <div className="convertbox">
                                                    <a onClick={this.exshowOutputCoin}>
                                                        {this.filteredImageLink(this.state.exfilteredTxtOutput, 'output')}
                                                        <i><img src={droparrow} alt={droparrow} /></i>
                                                    </a>
                                                </div>
                                            </span>
                                            <input className="form-control form-control-lg" value={this.state.outputAmt} onChange={this.examtCalOutputEvent} />
                                        </div>
                                    </div>

                                    <div className={ (this.state.exisShowInputAllowence && this.state.exisShowOutputAllowence) ? 'd-flex' : 'text-center' }>
                                        <div className="col-md-6 p-1">
                                            { this.state.exisShowInputAllowence &&
                                                <button className="btn sitebtn btn-block" onClick={() => this.approveTokenEvent('input')}>Approve { this.state.exfilteredTxtInput }</button>
                                            }
                                        </div>

                                        <div className="col-md-6 p-1">
                                            { this.state.exisShowOutputAllowence &&
                                                <button className="btn sitebtn btn-block" onClick={() => this.approveTokenEvent('output')}>Approve { this.state.exfilteredTxtOutput }</button>
                                            }
                                        </div>

                                        { this.showLiquidityBtn() &&
                                            <div className="form-group c-btn">
                                              <button className="btn sitebtn btn-block" onClick={() => this.addLiquidity() }>Add Liquidity</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h1 className="heading-title">
                            How It Works
                        </h1>
                        <div className="h-content">
                            { this.state.cms.howItWorks.length > 0 &&
                                <div className="h-box">
                                    <h6 className="h6">1. { this.state.cms.howItWorks[0].title_1 }</h6>
                                    <p className="content">{this.state.cms.howItWorks[0].content_1}</p>
                                </div>
                            }

                            { this.state.cms.howItWorks.length > 1 &&
                                <div className="h-box">
                                    <h6 className="h6">2. { this.state.cms.howItWorks[1].title_2 }</h6>
                                    <p className="content">{this.state.cms.howItWorks[1].content_2}</p>
                                </div>
                            }

                            { this.state.cms.howItWorks.length > 2 &&
                                <div className="h-box">
                                    <h6 className="h6">3. { this.state.cms.howItWorks[2].title_3 }</h6>
                                    <p className="content">{this.state.cms.howItWorks[2].content_3}</p>
                                </div>
                            }

                            { this.state.cms.howItWorks.length > 3 &&
                                <div className="h-box">
                                    <h6 className="h6">4. { this.state.cms.howItWorks[3].title_4 }</h6>
                                    <p className="content">{this.state.cms.howItWorks[3].content_4}</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="aboutbg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <h1 className="heading-title">About US</h1>
                        <h6 className="h6">{ Parse(this.state.cms.aboutus.replace(/\n/g, "<br />")) }</h6>
                    </div>
                    <div className="col-md-6">


                    </div>
                </div>
            </div>
        </section>
        <section className="faq-bg">
            <div className="container">
                <h1 className="heading-title">Frequently Asked Questions</h1>

                <div className="wrapper" id="accordionExample">
                    { this.state.faqs.map((data, i) => {
                        return <div className="accordion-item" key={i}>
                            <h2 className="accordion-header" id={`heading-${i}`}>
                                <button className={`accordion-button ${ (i === 0) ? '' : 'collapsed' }`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${i}`} aria-expanded={ (i === 0) ? true : false} aria-controls={`collapse-${i}`}>
                                    <h4> { data.title }</h4>
                                </button>
                            </h2>
                            <div id={`collapse-${i}`} className={`accordion-collapse collapse ${ (i === 0) ? 'show' : '' }`} aria-labelledby={`heading-${i}`} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p className="content">{ data.content }</p>
                                </div>
                            </div>
                        </div>
                        })
                    }
                </div>

                { this.state.faqSeeMore && 
                    <div className="morebtn text-center pt-3">
                        <Link to="/faq" className='btn sitebtn'>See More</Link>
                    </div>
                }
            </div>
        </section>

        <Modal
            show={this.state.inputCoinShow}
            onHide={() => this.showInputCoin}
            dialogClassName="modalbgt cryptomodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Select Coin </Modal.Title>
                <button className="close" onClick={this.showInputCoin}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <form className="siteformbox">
                    <TokenComponent data={this.state.tokensBalance} name="input" clickHandler={this.filteredItemEvent} filterTxtName={this.state.filteredTxtOutput}></TokenComponent>
                </form>
            </Modal.Body>
        </Modal>

        <Modal
            show={this.state.outputCoinShow}
            onHide={() => this.showOutputCoin}
            dialogClassName="modalbgt cryptomodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Select Coin</Modal.Title>
                <button className="close" onClick={this.showOutputCoin}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <form className="siteformbox">
                    <TokenComponent
                        data={this.state.tokensBalance}
                        name="output"
                        clickHandler={this.filteredItemEvent}
                        filterTxtName={this.state.filteredTxtInput}
                    ></TokenComponent>
                </form>
            </Modal.Body>
        </Modal>

        <Modal
            show={this.state.exinputCoinShow}
            onHide={() => this.exshowInputCoin}
            dialogClassName="modalbgt cryptomodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Select Coin </Modal.Title>
                <button className="close" onClick={this.exshowInputCoin}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <form className="siteformbox">
                    <TokenListWithBalanceComponent
                        data={this.state.tokensBalance}
                        name="input"
                        clickHandler={this.filterLiquidity}
                        filterTxtName={this.state.exfilteredTxtOutput}
                    ></TokenListWithBalanceComponent>
                </form>
            </Modal.Body>
        </Modal>

        <Modal
            show={this.state.exoutputCoinShow}
            onHide={() => this.exshowOutputCoin}
            dialogClassName="modalbgt cryptomodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Select Coin</Modal.Title>
                <button className="close" onClick={this.exshowOutputCoin}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <form className="siteformbox">
                    <TokenListWithBalanceComponent
                        data={this.state.tokensBalance}
                        name="output"
                        clickHandler={this.filterLiquidity}
                        filterTxtName={this.state.exfilteredTxtInput}
                    ></TokenListWithBalanceComponent>
                </form>
            </Modal.Body>
        </Modal>

        <Modal
            show={this.state.isPayPopup}
            onHide={() => this.payPopupAction}
            size="sm"
            dialogClassName="modalbgt"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Access To Swap</Modal.Title>
                <button className="close" onClick={this.payPopupAction}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <p className="fs-5">If you want to access the swap, you should pay the processing fee of <b>{ this.state.payAmt } { (this.service.network() == "polygon") ? "MATIC" : "ETH" }</b>.</p>
            </Modal.Body>
            <Modal.Footer>
            { (this.service.address()) &&
                <div className="text-center">
                  <button className="text-center btn btn-sm sitebtn" onClick={() => this.payEvent()}>Pay</button>
                </div>
              }

                { !this.service.address() &&
                    <div className="text-center">
                        <ConnectWallet name="menu"></ConnectWallet>
                    </div>
                }
            </Modal.Footer>
        </Modal>

        <ToastContainer autoClose={5000} />
        
        <ExchangeUtil
                ref={this.exchangeUtilRef}
                clickHandlerLiquidity={this.removeLiquidityEvt}
                clickHandlerExchangeError={this.warningMessage}
                clickHandlerRemoveTokenApprove={this.removeTokenApproveProviderEvt}
                clickHandlerTokenApprove={this.tokenApproveConfirmed}
                clickHandlerAddLiquidity={this.addLiquidityConfirmed}
            ></ExchangeUtil>
        <SwapUtil
                ref={this.swapUtilRef}
                clickHandlerSpenderApprove={this.spenderApproveConfirmed}
                clickHandlerSwapConfirmed={this.swapConfirmed}
                clickHandlerWarningError={this.warningMessage}
        ></SwapUtil>
        <PaymentUtil ref={this.paymentUtilRef} clickHandlerPay={this.paymentProccess} clickHandlerPayError={this.payWarningMessage}></PaymentUtil>
        </div>
    }
}

export default Home;
