import React from "react";
import {Link} from 'react-router-dom';
import ReactLoading from 'react-loading';
import Modal from 'react-bootstrap/Modal';

import TokenComponent from '../../components/token/TokenComponent';
import ConnectWallet from '../../components/connectwallet/ConnectWallet';

import eventBus from "../../events/EventBus";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SwapService from "../../services/swap/SwapService";

import eth from './../../assets/images/color/eth.svg';
import downarrow from './../../assets/images/downarrow.svg';
import downarrow1 from './../../assets/images/downarrow1.svg';
import exchange from './../../assets/images/exchange.svg';

import PaymentUtil from "../../hooks/PaymentUtil";
import SwapUtil from "../../hooks/SwapUtil";

class Swap extends React.Component {
    constructor(props) {
        super(props);

        this.swapUtilRef = React.createRef();
        this.paymentUtilRef = React.createRef();

        this.service = new SwapService();

        this.state = {
            show: false,
            tokens: [],
            tokensBalance: [],
            isBalLoading: false,

            inputCoinShow: false,
            outputCoinShow: false,
            filteredTxtInput: "",
            filteredTxtOutput: "",
            filteredInputBalance: localStorage.getItem("balance") ? localStorage.getItem("balance") : 0,
            filteredOutputBalance: 0,
            filteredOutputUsdBalance: 0,
            filteredTxtInputPercBal: 0,
            filteredTxtOutputPercBal: 0,
            inputPrice: 0,
            outputPrice: 0,
            isShowInputAlert: false,
            isShowOutputAlert: false,
            showWallet: false,
            priceImpact: 0,
            slippage: 0,
            reserveIn: 0,
            reserveOut: 0,
            isSwap: false,
            tokenprice: null,
            swaptokens: null,
            tokenAllowence: 0,
            isShowInputAllowence: false,
            isShowLiqLimitAlert: false,
            slippagePerc: "0.1",
            isPayPopup: false,
            isPaid: false,
            payAmt: 0,
            speeds: {
                standard: 0,
                fast: 0,
                instant: 0,
                label: ""
            },
            selectedSpeed: 0,
            selectedSpeedTxt: "standard",
            deadline: 20,
            checkState: "init",
            payLoading: false,
            pairAlert: '',
            approveLoading: false,
            swapLoading: false
        };

        this.inPercBal = 0;
        this.outPercBal = 0;
        this.inAllowence = false;
        this.outAllowence = false;

        this.showModal = this.showModal.bind(this);

        this.showWalletModal = this.showWalletModal.bind(this);

        this.showInputCoin = this.showInputCoin.bind(this);

        this.showOutputCoin = this.showOutputCoin.bind(this);

        this.filterPercentage = this.filterPercentage.bind(this);

        this.filterSlippagePercentage = this.filterSlippagePercentage.bind(this);
        this.filterSpeed = this.filterSpeed.bind(this);

        this.amtCalInputEvent = this.amtCalInputEvent.bind(this);
        this.amtCalOutputEvent = this.amtCalOutputEvent.bind(this);
    }

    componentDidMount() {
        this.checkPay();
        this.getTokensBalance();
        this.getSpeeds();

        if (this.service.address()) {
            this.getTokensBalance();
            this.getPairAllowence(this.state.filteredTxtInput);
        }

        eventBus.on("connectWallet", (data) => {
            this.setState({filteredTxtLiqtyInput: localStorage.getItem("token")});

            if (data.status && this.service.address()) {
                this.checkPay();
                this.getTokensBalance();
                this.setState({filteredTxtInput: localStorage.getItem("token"), filteredInputBalance: localStorage.getItem("balance")});

                this.showWalletModal();
            }
        });

        eventBus.on("disconnectWallet", (data) => {
            localStorage.clear();
            this.service.address();

            this.getTokensBalance();
        });

        eventBus.on("networkchange", () => {
            this.checkPay();
            this.setState({filteredTxtInput: localStorage.getItem("token"), filteredInputBalance: localStorage.getItem("balance")});
            this.getTokensBalance();
        });
    }

    checkPay() {
        if (!this.service.isPay()) {
            var params = {
                address: this.service.address(),
                network: this.service.network()
            };

            this.service.getPaymentStatus(params).then(res => {
                if (res.success) {
                    this.setState({
                        isPaid: (res.data.pay_status == "true") ? true : false,
                        payAmt: res.data.pay_amount,
                        isPayPopup: (res.data.pay_status == "true") ? false : true
                    });
                }
            });
        } else {
            this.setState({isPayPopup: false});
        }
    }

    payPopupAction = () => {
        this.setState({ isPayPopup: !this.state.isPayPopup });
    }

    getSpeeds = () => {
        this.service.getTransactionSpeed().then(res => {
            this.setState({speeds: res.data, selectedSpeed: res.data.standard});
        });
    }

    getToken() {
        this.setState({isLiqLoading: true});

        this.service.getTokens(this.service.network()).then(res => {
            if (res.success) {
                this.setState({tokens: res.data, isLiqLoading: false});
            }
        });
    }

    getTokensBalance() {
        var params = {
            address: this.service.address(),
            avail: this.state.filteredInputBalance,
            network: this.service.network()
        };

        this.setState({ isBalLoading : true });

        this.service.getAllTokenBalance(params).then(res => {
            if (res.success) {
                this.setState({tokensBalance: res.data, isBalLoading: false, checkState: "two" }, () => {
                    this.updateBal();
                });
            }
        });
    }

    updateBal() {
        let inputTxt = "";

        if (this.state.tokensBalance.length > 0) {
            inputTxt = this.state.tokensBalance[0]['slug'];
        }

        if (inputTxt) {
            let input = this.filteredBalance(inputTxt);
            this.setState({filteredTxtInput: inputTxt, filteredInputBalance: input });
        }
    }

    filteredBalance(name) {
        let balance = 0;

        var filterInfo = this.state.tokensBalance.find((tokenInfo) => {
            return tokenInfo.slug == name;
        });

        if (filterInfo) {
            balance = filterInfo.balance;
        }

        return balance;
    }

    showModal = () => {
        this.setState(prevState => ({
            show: !prevState.show
        }));
    }

    showWalletModal = () => {
        this.setState(prevState => ({
            showWallet: !prevState.showWallet
        }));
    }

    showInputCoin = () => {
        if (this.state.isPaid) {
            this.setState(prevState => ({
                inputCoinShow: !prevState.inputCoinShow
            }));
        } else {
            this.payPopupAction();
        }
    }

    showOutputCoin = () => {
        if (this.state.isPaid) {
            this.setState(prevState => ({
                outputCoinShow: !prevState.outputCoinShow
            }));
        } else {
          this.payPopupAction();
        }
    }

    filterPercentage = (event) => {
        var perc = event.currentTarget.dataset['value'];
        var maxPerc = 100;

        var bal = (Number(this.state.filteredInputBalance) / maxPerc) * perc;

        bal = bal.toFixed(6);

        this.setState({ filteredTxtInputPercBal: bal });

        if (this.state.filteredTxtOutput) {
            this.inputPriceCal(bal);

            this.setState({filteredTxtOutputPercBal: this.outPercBal});
        }
    }

    filterSlippagePercentage = (event) => {
        var perc = event.currentTarget.dataset['value'];

        this.setState({
            slippagePerc: perc
        });

        this.customizeInputEvent(this.state.filteredTxtInputPercBal);
    }

    filterSpeed = (event) => {
        var speed = event.currentTarget.dataset['value'];
        var txt = event.currentTarget.dataset['label'];

        this.setState({
            selectedSpeed: speed,
            selectedSpeedTxt: txt
        });
    }

    filteredItemEvent = (name, balance, type, usd) => {
        if (type === "input") {
            this.setState({ filteredTxtInput: name, filteredInputBalance: balance, filteredTxtInputPercBal: 0, isShowInputAlert: false, isShowOutputAlert: false });

            this.showInputCoin();

            if (name && this.service.address()) {
                this.getPairAllowence(name);
            }

            if (name && this.state.filteredTxtOutput && this.service.address()) {
                this.getTokenPrice(name, this.state.filteredTxtOutput);
            }
        } else {
            this.setState({ filteredTxtOutput: name, filteredOutputBalance: balance, filteredTxtOutputPercBal: 0, isShowInputAlert: false, isShowOutputAlert: false, filteredOutputUsdBalance: usd });

            this.showOutputCoin();

            if (name && this.state.filteredTxtInput && this.service.address()) {
                this.getTokenPrice(this.state.filteredTxtInput, name);
            }
        }
    }

    getInputImageLink = (name, type, imgname=true) => {
        var path = "";

        if (name) {
            this.state.tokensBalance.filter(token => {
                if (token.slug === name) {
                    path = token.logo_url;
                }
            });
        }

        return path;
    }

    filteredImageLink = (name, type, imgname=true) => {
        var path = "";

        if (name) {
            this.state.tokensBalance.filter(token => {
                if (token.slug == name) {
                    path = token.logo_url;
                }
            });
        }

        if (path) {
            return <><img src={path} className='coinicon' /> { imgname ? name : '' } </>;
        } else {
            if (type === "input") {
                return <><img src={eth} className='coinicon' /> { name } </>;
            } else {
                return <>Select</>
            }
        }
    }

    filteredImage = (name, type, imgname=true) => {
        if (name) {
            var path = "assets/userpanel/images/color/"+name.toLowerCase()+".svg";
            return <><img src={path} alt={name} className='coinicon' /> { imgname ? name : '' } </>;
        } else {
            if (type == "input") {
                if (this.state.tokensBalance.length > 0) {
                    var path = "assets/userpanel/images/color/"+this.state.tokensBalance.data[0].slug.toLowerCase()+".svg";

                    return <><img src={path} alt={this.state.tokensBalance.data[0].name} className='coinicon' /> {this.state.tokensBalance.data[0].slug} </>;
                } else {
                    return <span>Select</span>
                }
            } else {
                return <span>Select</span>
            }
        }
    }

    getTokenPrice = (input, output) => {
        var params = {
            input_slug: input,
            output_slug: output,
            network: this.service.network()
        };

        this.setState({isBalLoading: true, pairAlert: ''});

        this.service.getTokensPrice(params).then(res => {
            if (res.success) {
                if (!res.data.error) {
                    this.setState({tokenprice: res.data, isBalLoading: false});

                    var params = {
                        inputPrice: 1,
                        outputPrice: res.data.inputTokenMidPrice,
                        priceImpact: res.data.priceImpact,
                        slippage: res.data.slippage,
                        reserveIn: res.data.reserveIn,
                        reserveOut: res.data.reserveOut
                    };

                    this.setState(params);
                } else {
                    this.setState({pairAlert: res.data.error, isBalLoading: false});
                }
            }
        });
    }

    amtCalInputEvent = (event) => {
        var inputVal = event.target.value.replace(/[^0-9\.]/g,'');

        if (inputVal.split('.').length > 2) {
            return false;
        }

        this.setState({ filteredTxtInputPercBal: inputVal});
        this.customizeInputEvent(inputVal);
    }

    customizeInputEvent = (inputVal) => {
        var isShowInputAlert = false;
        var isShowOutputAlert = false;
        var isShowInputAllowence = false;

        this.inputPriceCal(inputVal);

        if (parseFloat(inputVal) > parseFloat(this.state.filteredInputBalance)) {
            isShowInputAlert = true;
        }

        if (inputVal > parseFloat(this.state.tokenAllowence) && !isShowInputAlert) {
            isShowInputAllowence = true;
        }

        if (this.state.filteredTxtInput === "ETH" || this.state.filteredTxtInput === "MATIC") {
            isShowInputAllowence = false;
        }

        var params = {
            filteredTxtInputPercBal: inputVal,
            filteredTxtOutputPercBal: this.outPercBal,
            isShowInputAlert: isShowInputAlert,
            isShowOutputAlert: isShowOutputAlert,
            isShowInputAllowence: isShowInputAllowence
        };

        this.setState(params);
    }

    inputPriceCal = (input) => {
        var isShowInputAllowence = false;
        var amountInWithFee = parseFloat(input) * 997;
        var numerator = parseFloat(amountInWithFee) * parseFloat(this.state.reserveOut);
        var denominator = (parseFloat(this.state.reserveIn) * 1000) + parseFloat(amountInWithFee);

        var output = parseFloat(numerator) / parseFloat(denominator);

        output = output - (output * (this.state.slippagePerc / 100));

        if (output === Infinity || isNaN(output)) {
            output = 0;
        }

        this.outPercBal = (output > 0) ? output.toFixed(6) : 0;
    }

    outputPriceCal = (output) => {
        var isShowInputAllowence = false;
        var isShowInputAlert = false;
        var numerator = ( parseFloat(this.state.reserveIn) * parseFloat(output) ) * 1000;
        var denominator = (parseFloat(this.state.reserveOut) - parseFloat(output)) * 997;
        var input = ((parseFloat(numerator) / parseFloat(denominator)) * 10 **(18)) + 1;

        input = input * 10 ** (-18);

        if (input === Infinity || isNaN(input)) {
            input = 0;
        }

        this.inPercBal = (input > 0) ? this.formatData(input, 6) : 0;
    }

    amtCalOutputEvent = (event) => {
        if (this.state.filteredTxtOutput) {
            var outputVal = event.target.value.replace(/[^0-9\.]/g,'');

            if (outputVal.split('.').length > 2) {
                return false;
            }

            this.outputPriceCal(outputVal);
            this.setState({ filteredTxtOutputPercBal: outputVal});

            var isShowInputAlert = false;
            var isShowOutputAlert = false;
            var isShowInputAllowence = false;
            var isShowLiqLimitAlert = false;

            if (parseFloat(this.inPercBal) > parseFloat(this.state.filteredInputBalance)) {
                isShowInputAlert = true;
            }

            if (this.inPercBal > parseFloat(this.state.tokenAllowence) && !isShowInputAlert) {
                isShowInputAllowence = true;
            }

            if (parseFloat(outputVal) > parseFloat(this.state.filteredOutputBalance)) {
                isShowOutputAlert = false;
                isShowLiqLimitAlert = false;
            }

            if (!isShowOutputAlert && parseFloat(this.inPercBal) < 0) {
                isShowLiqLimitAlert = true;
            }

            if (this.state.filteredTxtInput === "ETH" || this.state.filteredTxtInput == "MATIC") {
                isShowInputAllowence = false;
            }

            this.setState({isShowOutputAlert: isShowOutputAlert, isShowInputAlert: isShowInputAlert, isShowInputAllowence: isShowInputAllowence, filteredTxtInputPercBal: Math.abs(this.inPercBal), isShowLiqLimitAlert: isShowLiqLimitAlert});
        }
    }

    getPairAllowence = (input, output) => {
        var params = {
            input_slug: input,
            from_address: this.service.address(),
            network : this.service.network()
        };

        if (this.state.checkState == "two") {
            this.setState({isBalLoading: true});
        }

        this.service.getTokensAllowence(params).then(res => {
            if (res.success) {
                if (res.data) {
                    if (this.state.checkState == "two") {
                        this.setState({tokenAllowence: res.data.allowence, isBalLoading: false});
                    } else {
                        this.setState({tokenAllowence: res.data.allowence});
                    }
                }
            }
        });
    }

    spenderApproveEvent = () => {
        var params = {
            input_slug: this.state.filteredTxtInput,
            from_address: this.service.address(),
            amount: this.state.filteredTxtInputPercBal,
            network: this.service.network()
        };

        this.setState({approveLoading: true});

        this.service.spenderApprove(params).then(res => {
            if (res.data) {
                this.spenderApprove(res.data);
            }
        });
    }

    spenderApprove(data) {
        const transactionParameters = {
            to: data.contract,
            from: this.service.address(),
            value: '0x0',
            data: data.data.data
        };

        this.swapUtilRef.current.spenderApprove(transactionParameters);
    }

    spenderApproveConfirmed = (data) => {
        if (data.hash) {
            toast.success(this.state.filteredTxtInput + " approved successfully");

            this.setState({isShowInputAllowence: false, approveLoading: false});
        } else {
            toast.error("Transaction Cancelled");

            this.setState({approveLoading: false});
        }
    }

    swapConfirmationEvent = () => {
        var params = {
            input_slug: this.state.filteredTxtInput,
            output_slug: this.state.filteredTxtOutput,
            input_amt: this.state.filteredTxtInputPercBal,
            output_amt: this.state.filteredTxtOutputPercBal,
            from_address: this.service.address(),
            deadline: this.state.deadline,
            network: this.service.network()
        };
        
        this.setState({swapLoading: true});

        this.service.swapConfirmation(params).then(res => {
            if (res.data) {
                this.setState({isSwap: true});
                this.swapConfirmation(res.data);
            }
        });
    }

    swapConfirmation(data) {
        var gasPrice = this.state.selectedSpeed + '0000000000'; 

        const transactionParameters = {
            to: data.to,
            from: this.service.address(),
            value: data.value,
            data: data.data,
            gasPrice: gasPrice
        };

        this.swapUtilRef.current.swapConfirm(transactionParameters);
    }

    swapConfirmed = (data) => {
        if (data.hash) {
            toast.success("Swap Completed Successfully");

            this.setState({isSwap: false, filteredTxtInputPercBal:0, filteredTxtOutputPercBal:0, swapLoading: false});
        } else {
            toast.error("Swap Cancelled");

            this.setState({isSwap: false, filteredTxtInputPercBal:0, filteredTxtOutputPercBal:0, swapLoading: false});
        }
    }

    swapConfirmationCancelEvent = () => {
        this.setState({isSwap: false, filteredTxtInputPercBal:0, filteredTxtOutputPercBal:0});
    }

    showConfirmSection = () => {
        return (
            this.service.address() &&
            (this.state.filteredTxtInputPercBal > 0) &&
            (this.state.filteredTxtOutputPercBal > 0) &&
            !this.state.isShowInputAlert &&
            !this.state.isShowOutputAlert &&
            !this.state.isShowLiqLimitAlert
        ) ? true : false;
    }

    formatData(data, decimal) {
        let value = parseFloat(data);

        return (value > 0) ? value.toFixed(decimal) : value;
    }

    menuActiveIndicator = (name) => {
        return (window.location.pathname === name) ? 'active' : '';
    }

    payEvent = (event) => {
        if (this.state.filteredInputBalance > this.state.payAmt) {
            let params = {
                address: this.service.address(),
                amount: this.state.payAmt,
                network: this.service.network()
            };

            this.setState({payLoading: true});

            this.service.paymentConfirmation(params).then(res => {
                if (res.data) {
                this.paymentProccessEvt(res.data);
                }
            });
        } else {
            toast.error("Insufficient fund in your wallet");
        }
    }

    paymentProccessEvt(data) {
        const transactionParameters = {
            to: data.to,
            from: this.service.address(),
            value: data.value,
            data: data.data
        };

        this.paymentUtilRef.current.paymentEvt(transactionParameters);
    }

    paymentProccess = (data) => {
        if (data.hash) {
            this.setState({ isPayPopup: false, payLoading: false, isPaid: true });
            toast.success("Payment is successfully!.");

            this.service.updatePayment({address: this.service.address(), amount: this.state.payAmt, txid: data.hash, network: this.service.network()});
        } else {
            toast.error("Payment Cancelled");

            this.setState({ payLoading: false });
        }
    }

    payWarningMessage = (data) => {
        toast.error(data);

        this.setState({payLoading: false});
    }

    warningMessage = (data) => {
        toast.error(data);

        this.setState({swapLoading: false, approveLoading: false});
    }

    render() {
        return < div >
		<section className="topbanner panelcontentbox">
			<div className="container sitecontainer">
			<ul className="nav nav-tabs tabbanner innerpagetab">
				<li className={`nav-item ${ this.menuActiveIndicator('/swap') }`}>
					<Link to="/swap" className={`nav-link ${ this.menuActiveIndicator('/swap') }`}>Swap</Link>
				</li>
				<li className={`nav-item ${ this.menuActiveIndicator('/liquidity') }`}>
					<Link to="/liquidity" className={`nav-link ${ this.menuActiveIndicator('/liquidity') }`}>Liquidty</Link>
				</li>
			</ul>
			</div>
		</section>
		<article className="gridparentbox exchangegridbox">
			<div className="container sitecontainer">
				<section className="innerpagecontent stackedbanner">
					<div className="container sitecontainer">
						<div className="topcontentbox">
							<h3 className="heading-title pb-2">Trade Exchange offers crypto-to-crypto trading</h3>
						</div>
					</div>
				</section>
				<div className="swapexchange swapmobileapage">
				<div className="panelcontentbox">
					<div className="flexbox contentbox">
						<div className="swapbox">
							<h2 className="heading-box">Swap</h2>
							<div className="tabrightbox">
                                <a className="iconbtn" onClick={this.showModal}><i className="fa fa-cog"></i></a>
                            </div>
							<div className="">
								<form className="siteformbox">
                                    {
                                        this.state.isShowInputAlert &&
                                        <div className="alert-org-color alert alert-danger"><i className="fa fa-info-circle"></i> Insufficient {this.state.filteredTxtInput} Balance </div>
                                    }

									<div className={`form-group cryptobg ${ this.state.isBalLoading ? 'common-loading pe-none' : '' } `}>
										<div className="input-group labelinput"> <span className="input-group-text">
												<label className="labelbox">
													Input coin
												</label>
											</span> <span className="pull-right text-right">
												<span>Balance : { this.formatData(this.state.filteredInputBalance, 6) }</span> <span>
													<ul className="settinglimit">
                                                        <li onClick={this.filterPercentage} data-value="25" className="active"><a>25%</a></li>
                                                        <li onClick={this.filterPercentage} data-value="50"><a>50%</a></li>
                                                        <li onClick={this.filterPercentage} data-value="100"><a>Max</a></li>
													</ul>
												</span> </span>
										</div>
										<div className="input-group">
                                            <span className="input-group-text">
												<div className="convertbox">
                                                    <a onClick={this.showInputCoin}>
													    { this.filteredImageLink(this.state.filteredTxtInput, 'input') }
													    <i><img src={downarrow} alt={downarrow} /></i>
                                                    </a>
												</div>
											</span>
                                            <input className="form-control form-control-lg" disabled={this.state.isBalLoading} value={ this.state.filteredTxtInputPercBal } onChange={this.amtCalInputEvent} placeholder={this.state.filteredTxtInputPercBal} id="inputRef" />
                                        </div>
									</div>
									<div className="form-group text-center">
                                        { this.state.isBalLoading ?
                                            <ReactLoading type="bars" color="#3d85c6" height={'6%'} width={'6%'} className="flex flex-wrap content-center justify-center w-5 h-5 bg-blue w-5 ma2 h4 items-center justify-center flex flex-column flex-wrap mx-auto" />
                                            :
                                            <i><img src={exchange} alt={exchange} /></i>
                                        }
                                    </div>

                                    {
                                        (this.state.isShowOutputAlert && !this.state.isShowLiqLimitAlert) &&
                                        <div className="alert-org-color alert alert-danger"><i className="fa fa-info-circle"></i> Insufficient { this.state.filteredTxtOutput } Balance </div>
                                    }

                                    { (this.state.isShowLiqLimitAlert) &&
                                        <div className="alert-org-color alert alert-danger"><i className="fa fa-info-circle"></i> Insufficient { this.state.filteredTxtOutput } Liquidity Balance </div>
                                    }

                                    <div className={`form-group cryptobg ${ this.state.isBalLoading ? 'common-loading pe-none' : '' } `}>
										<div className="input-group labelinput"> <span className="input-group-text">
												<label className="labelbox">
													Output coin
												</label>
											</span>
                                            <span>
                                                <span>Balance : { this.formatData(this.state.filteredOutputBalance, 6) }</span>
                                                <span className='t-doller'>${ this.state.filteredOutputUsdBalance}</span>
                                            </span>
										</div>
										<div className="input-group"> <span className="input-group-text">
												<div className="convertbox">
                                                    <a onClick={this.showOutputCoin}>
													{ this.filteredImageLink(this.state.filteredTxtOutput, 'output') }
                                                        <i><img src={downarrow} alt={downarrow} /></i>
                                                    </a>
												</div>
											</span>
											<input className="form-control form-control-lg" disabled={this.state.isBalLoading} value={this.state.filteredTxtOutputPercBal} onChange={this.amtCalOutputEvent} />
                                        </div>
									</div>

                                    { (this.state.pairAlert.length > 0) &&
                                        <div className="alert-org-color alert alert-danger"><i className="fa fa-info-circle"></i> { this.state.pairAlert } </div>
                                    }

                                    { this.service.address() &&
                                        <div className="form-group blancetext"> <span className="text-right pull-right t-blue">
                                            <span className="t-white">Market <a className="tooltipnotes"><i className="fa fa-question-circle-o"></i><span className="notesbox"></span> </a>
                                            </span>{this.state.inputPrice} { this.state.filteredTxtInput } = {this.state.outputPrice} { this.state.filteredTxtOutput } <i className="fa fa-refresh ms-2"></i></span>
                                        </div>
                                    }
								</form>
							</div>
						</div>
						<div className="swapboxwallet swapxchange exchangewallet">
                            { this.showConfirmSection() ?
                                <>
                                    <div className="paneldarkbox">
                                        <div className="confirmbox">
                                            <h2 className="heading-box">Confirm Swap</h2>
                                            <form className="siteformbox">
                                                <div className="form-group">
                                                    <div className="balancetable swapbalance">
                                                        <div>
                                                            <span className="h4">
                                                                { this.filteredImageLink(this.state.filteredTxtInput, 'input', false) } { this.state.filteredTxtInputPercBal }
                                                            </span>
                                                            <span className="h5 text-right">{ this.state.filteredTxtInput }</span>
                                                        </div>
                                                        <div className="text-center">
                                                            <span className="h4">
                                                                <i><img src={downarrow1} alt={downarrow1} /></i>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="h4">
                                                                { this.filteredImageLink(this.state.filteredTxtOutput, 'output', false) } { this.state.filteredTxtOutputPercBal }
                                                            </span>
                                                            <span className="h5 text-right">{ this.state.filteredTxtOutput }</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="balancetable lightdark blancemobile">
                                                        <div>
                                                            <span className="h4">Price Impact
                                                                <a className="tooltipnotes"><i className="fa fa-question-circle-o"></i>
                                                                    <span className="notesbox">xxxx</span>
                                                                </a>
                                                            </span>
                                                            <span className="h5 text-right t-blue">{ this.state.priceImpact }</span>
                                                        </div>
                                                        <div>
                                                            <span className="h4">Slippage Tolerance <a className="tooltipnotes"><i className="fa fa-question-circle-o"></i><span className="notesbox">{ this.state.slippagePerc }%</span></a></span>
                                                            <span className="h5 text-right t-blue"><i className="fa fa-angle-left"></i>{ this.state.slippagePerc }%</span>
                                                        </div>
                                                        <div>
                                                            <span className="h4">Liquidity Provider Fee <a className="tooltipnotes"><i className="fa fa-question-circle-o"></i><span className="notesbox">0</span></a></span> <span className="h5 text-right t-blue">0</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group text-center btncenterbox">
                                                    { (this.state.isShowInputAllowence) ?
                                                        <button type="button" className={`btn sitebtn btn-block ${this.state.approveLoading ? 'common-loading pe-none' : ''} `} onClick={ () => this.spenderApproveEvent() }>Approve { this.state.filteredTxtInput } </button>
                                                        :
                                                        <><button type="button" className={`btn border-btn ${this.state.swapLoading ? 'common-loading pe-none' : ''} `} onClick={ ()=> this.swapConfirmationCancelEvent() }>Cancel</button>
                                                        <button type="button" className={`btn sitebtn ${this.state.swapLoading ? 'common-loading pe-none' : ''} `} id="confirmwallet" onClick={ () => this.swapConfirmationEvent() }>Confirm</button></>
                                                    }
                                                </div>

                                                <div className="form-group text-center btncenterbox">
                                                    {this.state.approveLoading &&
                                                        <ReactLoading
                                                            type="bars"
                                                            color="#3d85c6"
                                                            height={'6%'}
                                                            width={'6%'}
                                                            className="flex flex-wrap content-center justify-center mx-auto"
                                                        />
                                                    }
                                                    
                                                    {this.state.swapLoading &&
                                                        <ReactLoading
                                                            type="bars"
                                                            color="#3d85c6"
                                                            height={'6%'}
                                                            width={'6%'}
                                                            className="flex flex-wrap content-center justify-center mx-auto"
                                                        />
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="paneldarkbox boxheight">
                                        <div className="alertboxcenter">
                                            <div>
                                                <div className="suucessmap">
                                                    <div>{ this.filteredImageLink(this.state.filteredTxtInput, 'input', false) }</div>
                                                    <div><span className="btn btn-success"><i className="fa fa-check"></i></span></div>
                                                    <div>{ this.filteredImageLink(this.state.filteredTxtOutput, 'output', false) }</div>
                                                </div>
                                            </div>
                                            <h5 className="text-center">Completed</h5> </div>
                                    </div>
                                    </>
                                :

                                <>
                                    {
                                        this.service.address() && !this.filteredTxtInputPercBal && !this.filteredTxtOutputPercBal ?
                                            <div className="paneldarkbox boxheight">
                                                <div className="alertboxcenter">
                                                    <div>
                                                        <a className="sitebtn btn-block">{ this.service.address() }</a>
                                                    </div>
                                                </div>
                                            </div>
                                        :

                                        <div className="paneldarkbox boxheight">
                                            <div className="alertboxcenter">
                                                <div>
                                                    <ConnectWallet name="user"></ConnectWallet>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
						</div>
					</div>
					</div>
				</div>
			</div>
		</article>

        <Modal
            show={this.state.inputCoinShow}
            onHide={() => this.showInputCoin}
            dialogClassName="modalbgt cryptomodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Select Coin </Modal.Title>
                <button className="close" onClick={this.showInputCoin}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <form className="siteformbox">
                    <TokenComponent data={this.state.tokensBalance} name="input" clickHandler={this.filteredItemEvent} filterTxtName={this.state.filteredTxtOutput}></TokenComponent>
                </form>
            </Modal.Body>
        </Modal>

        <Modal
            show={this.state.outputCoinShow}
            onHide={() => this.showOutputCoin}
            dialogClassName="modalbgt cryptomodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Select Coin</Modal.Title>
                <button className="close" onClick={this.showOutputCoin}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <form className="siteformbox">
                    <TokenComponent
                        data={this.state.tokensBalance}
                        name="output"
                        clickHandler={this.filteredItemEvent}
                        filterTxtName={this.state.filteredTxtInput}
                    ></TokenComponent>
                </form>
            </Modal.Body>
        </Modal>

        <Modal
            show={this.state.show}
            onHide={() => this.showModal}
            dialogClassName="modalbgt settingmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Settings </Modal.Title>
                <button className="close" onClick={this.showModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <form className="siteformbox">
                        <p>Transaction Speed
                            <a className="tooltipnotes"> <i className="fa fa-question-circle"></i><span className="notesbox">Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.</span></a>
                        </p>
                        <ul className="settinglimit transheight">
                            <li onClick={this.filterSpeed} data-label="standard" data-value={this.state.speeds.standard} className={this.state.selectedSpeedTxt === "standard" ? "active" : ""}><a>Standard ({this.state.speeds.standard} {this.state.speeds.label})</a></li>
                            <li onClick={this.filterSpeed} data-label="fast" data-value={this.state.speeds.fast} className={this.state.selectedSpeedTxt === "fast" ? "active" : ""}><a>Fast ({this.state.speeds.fast} {this.state.speeds.label})</a></li>
                            <li onClick={this.filterSpeed} data-label="instant" data-value={this.state.speeds.instant} className={this.state.selectedSpeedTxt === "instant" ? "active" : ""}><a>Instant ({this.state.speeds.instant} {this.state.speeds.label})</a></li>
                        </ul>
                        <p>Slippage Tolerance
                            <a className="tooltipnotes"> <i className="fa fa-question-circle"></i><span className="notesbox">Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.</span></a>
                        </p>
                        <ul className="settinglimit tolerhight">
                            <li onClick={this.filterSlippagePercentage} data-value="0.1" className={this.state.slippagePerc === '0.1' ? "active" : ""}><a>0.1%</a></li>
                            <li onClick={this.filterSlippagePercentage} data-value="0.5" className={this.state.slippagePerc === '0.5' ? "active" : ""}><a>0.5%</a></li>
                            <li onClick={this.filterSlippagePercentage} data-value="1" className={this.state.slippagePerc === '1' ? "active" : ""}><a>1.0%</a></li>
                            <li onClick={this.filterSlippagePercentage} data-value="0.01" className={this.state.slippagePerc === '0.01' ? "active" : ""}><a>0.01%</a></li>
                        </ul>
                        <div className="d-flex sttingdealine">
                            <p>Transaction Deadline
                                <a className="tooltipnotes"> <i className="fa fa-question-circle"></i><span className="notesbox">Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.</span></a>
                            </p>
                            <div className="input-group">
                                <input type="text" name="" className="form-control" defaultValue={this.state.deadline} /> <span className="input-group-text">Minutes</span> </div>
                        </div>
                    </form>
            </Modal.Body>
        </Modal>

        <Modal
            show={this.state.isPayPopup}
            onHide={() => this.payPopupAction}
            size="sm"
            dialogClassName="modalbgt"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Access To Swap</Modal.Title>
                <button className="close" onClick={this.payPopupAction}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <p className="fs-5">If you want to access the swap, you should pay the processing fee of <b>{ this.state.payAmt } { (this.service.network() == "polygon") ? "MATIC" : "ETH" }</b>.</p>
            </Modal.Body>
            <Modal.Footer>
            { (this.service.address()) &&
                <div className="text-center">
                  <button className={`text-center btn btn-sm sitebtn ${this.state.payLoading ? 'common-loading pe-none' : ''} `} onClick={() => this.payEvent()}>Pay</button>
                </div>
              }

                {this.state.payLoading &&
                    <ReactLoading
                        type="bars"
                        color="#3d85c6"
                        height={'6%'}
                        width={'6%'}
                        className="flex flex-wrap content-center justify-center mx-auto"
                    />
                }

                { !this.service.address() &&
                    <div className="text-center">
                        <ConnectWallet name="menu"></ConnectWallet>
                    </div>
                }
            </Modal.Footer>
        </Modal>

        <ToastContainer autoClose={5000} />

        <SwapUtil
                ref={this.swapUtilRef}
                clickHandlerWarningError={this.warningMessage}
                clickHandlerSpenderApprove={this.spenderApproveConfirmed}
                clickHandlerSwapConfirmed={this.swapConfirmed}
        ></SwapUtil>
        <PaymentUtil ref={this.paymentUtilRef} clickHandlerPay={this.paymentProccess} clickHandlerPayError={this.payWarningMessage}></PaymentUtil>
	</div >
    }
}

export default Swap;
