import React from 'react';
import Parse from 'html-react-parser';

import BaseService from "../../services/common/BaseService";

class Privacy extends React.Component {
    constructor(props) {
        super(props);

        this.service = new BaseService();

        this.state = {
            privacy: ""
        };
    }

    componentDidMount() {
        this.loadPrivacy();
    }

    loadPrivacy() {
        this.service.getPrivacy().then(res => {
            if (res.data) {
                this.setState({privacy:res.data.content});
            }
        });
    }

    render() {
        return (
            <article className="gridparentbox">				
                <div className="container sitecontainer privacycontentpage">
                    <div className="innerpagecontent">
                        <div className="topcontentbox">
                            <h3 className="heading-title pb-2">Privacy Policy</h3>
                        </div>
                    </div>	
                    <div className="panelcontentbox">
                        <div className="contentbox">
                            { Parse(this.state.privacy) }
                        </div>
                    </div>
                </div>
            </article>
        );
    }
}

export default Privacy;