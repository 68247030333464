import React from 'react';
import Parse from 'html-react-parser';

import BaseService from "../../services/common/BaseService";

class Faq extends React.Component {
    constructor(props) {
        super(props);

        this.service = new BaseService();

        this.state = {
            faqs: []
        };
    }

    componentDidMount() {
        this.loadFaq();
    }

    loadFaq() {
        this.service.getFaq().then(res => {
            if (res.data) {
                this.setState({faqs:res.data.faqs});
            }
        });
    }

    render() {
        return (
            <article className="gridparentbox">
                <div className="container sitecontainer faqpage">
                    <div className="innerpagecontent">
                        <div className="topcontentbox">
                            <h3 className="heading-title pb-2">Frequently Asked Questions</h3>
                        </div>
                    </div>
                    <div className="panelcontentbox ">
                        <div className="contentbox">
                            <div className="wrapper" id="accordionExample">
                                { this.state.faqs.map((data, i) => {
                                    return <div className="accordion-item" key={i}>
                                        <h2 className="accordion-header" id={`heading-${i}`}>
                                            <button className={`accordion-button ${ (i == 0) ? '' : 'collapsed' }`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${i}`} aria-expanded={ (i === 0) ? true : false} aria-controls={`collapse-${i}`}>
                                                <h4> { data.title }</h4>
                                            </button>
                                        </h2>
                                        <div id={`collapse-${i}`} className={`accordion-collapse collapse ${ (i == 0) ? 'show' : '' }`} aria-labelledby={`heading-${i}`} data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p className="content">{ data.content }</p>
                                            </div>
                                        </div>
                                    </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        );
    }
}

export default Faq;