import React from 'react';
import Parse from 'html-react-parser';

import BaseService from "../../services/common/BaseService";

class Terms extends React.Component {
    constructor(props) {
        super(props);

        this.service = new BaseService();

        this.state = {
            content: ""
        };
    }

    componentDidMount() {
        this.loadTerms();
    }

    loadTerms() {
        this.service.getTerms().then(res => {
            if (res.data) {
                this.setState({content:res.data.content});
            }
        });
    }

    render() {
        return (
            <article className="gridparentbox">
                <div className="container sitecontainer privacycontentpage">
                    <div className="innerpagecontent">
                        <div className="topcontentbox">
                            <h3 className="heading-title pb-2">Terms and Conditions</h3>
                        </div>
                    </div>
                    <div className="panelcontentbox">
                        <div className="contentbox">
                            { Parse(this.state.content) }
                        </div>
                    </div>
                </div>
            </article>
        );
    }
};

export default Terms;