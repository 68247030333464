import React, { useState, useEffect } from "react";

export default function Theme(props) {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'daymode');

    const toggleTheme = () => {
        if (theme === 'daymode') {
            document.body.classList.remove("daymode");

            setTheme('nightmode');
        } else {
            document.body.classList.remove("nightmode");

            setTheme('daymode');
        }
    };

    useEffect(() => {
        localStorage.setItem('theme', theme);

        document.body.classList.add(theme);
    }, [theme]);

    return (
        <li className="nav-item modebg mobilehide">
            { theme === "daymode" ? 
                <a onClick={toggleTheme}><i className="fa fa-sun-o modeicon daymode" aria-hidden="true"></i></a>
                :
                <a onClick={toggleTheme}><i className="fa fa-moon-o modeicon nightmode" aria-hidden="true"></i></a>
            }
        </li>
    );
}