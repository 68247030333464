import React, { useState, useEffect, useMemo, Component } from "react";

import { useNetwork, useSwitchNetwork, useBalance } from 'wagmi';

import eventBus from "../../events/EventBus";


import eth from './../../assets/images/color/eth.svg';
import poly from './../../assets/images/color/matic.svg';

export default function Networks(props) {
    const [network, setNetwork] = useState(localStorage.getItem('network') || 'ethereum');
    const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork({
        onSuccess(data) {
            if(data.id == 1) {
                localStorage.setItem('network', "ethereum");
            } else {
                localStorage.setItem('network', "polygon");
            }

            eventBus.dispatch("networkchange");
        },

        onError(error) {
            console.log("error", error);
        }
    });

    const toggleNetwork = (network) => {
        setNetwork(network);
        localStorage.setItem('network', network);

        let networkId = 1;

        if (network == "ethereum") {
            networkId = 1;
        } else {
            networkId = 137;
        }

        if(localStorage.getItem("address") == null || localStorage.getItem("address") == '') {
            eventBus.dispatch("networkchange");
        } else {
            switchNetwork(networkId);
        }
    };

    useEffect(() => {
        localStorage.setItem('network', network);
    }, [network]);

    return (
        <li className="nav-item dropdown coinselectbox">
            <a href="#" role="button" className="nav-link dropdown-bs-toggle" data-bs-toggle="dropdown">
                { network == "ethereum" ? 
                    <><img src={eth} className="coinicon"/>Ethereum<i className="fa fa-caret-down"></i></>
                    :
                    <><img src={poly} className="coinicon"/>Polygon<i className="fa fa-caret-down"></i></>
                }
            </a>

            <div className="dropdown-menu">
                <a onClick={ () => toggleNetwork('ethereum')} className="nav-link dropdown-item"><img src={eth} className="coinicon" />Ethereum</a>
                <a onClick={ () => toggleNetwork('polygon')} className="nav-link dropdown-item"><img src={poly} className="coinicon" />Polygon</a>
            </div>
        </li>
    );
}