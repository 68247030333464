import BaseService from '../common/BaseService';

class ExchangeService extends BaseService {
    reserveA = 0;
    reserveB = 0;
    token1Decimals = 18;
    token2Decimals = 18;
    totalSupply = 0;

    async setLiquidity(data) {
        this.reserveA = data.reserveA;
        this.reserveB = data.reserveB;
        this.token1Decimals = data.token1Decimals;
        this.token2Decimals = data.token2Decimals;
        this.totalSupply = data.totalSupply;
    }

	async amtCalInputEvent(params) {
        var isShowInputAlert = false;
        var isShowOutputAlert = false;
        var isShowInputAllowence = false;
        var isShowOutputAllowence = false;
        var isShowAddLiq = false;
        var isAddLiqBtn = false;

        var token2Price = params.token2 ? params.token2 : 0;
        var total = (parseFloat(params.inputVal) > 0) ? parseFloat(params.inputVal) * parseFloat(token2Price) : 0;

        total = total.toFixed(6);

        if (Number(params.inputVal) > Number(params.inputOrg)) {
            isShowInputAlert = true;
            isShowInputAllowence = false;
            isShowOutputAllowence = false;
        } else {
            if (Number(params.inputVal) > Number(params.token1allowance) && !isShowOutputAlert) {
                isShowInputAllowence = true;
            }
        }

        if (Number(total) > Number(params.outputOrg)) {
            isShowOutputAlert = true;
            isShowInputAllowence = false;
            isShowOutputAllowence = false;
        } else {
            if (Number(total) > Number(params.token2allowance) && !isShowInputAlert) {
                isShowOutputAllowence = true;
            }
        }

        if (!isShowInputAllowence && !isShowOutputAllowence && params.inputVal && total) {
            isShowAddLiq = true;
        }

        var res = {
            inputAmt: params.inputVal,
            isShowInputAlert: isShowInputAlert,
            isShowOutputAlert: isShowOutputAlert,
            isShowInputAllowence: isShowInputAllowence,
            isShowOutputAllowence: isShowOutputAllowence,
            isShowAddLiq: isShowAddLiq,
            isAddLiqBtn: isAddLiqBtn,
            outputAmt: total
        };

        return Promise.resolve(res);
	}

	async amtCalOutputEvent(params) {
        var isShowInputAlert = false;
        var isShowOutputAlert = false;
        var isShowInputAllowence = false;
        var isShowOutputAllowence = false;
        var isShowAddLiq = false;
        var isAddLiqBtn = false;

        var token1Price = params.token1 ? params.token1 : 0;

        var total = (parseFloat(params.outputVal) > 0) ? parseFloat(params.outputVal) * parseFloat(token1Price) : 0;
        total = total.toFixed(6);

        if (Number(params.outputVal) > Number(params.outputOrg)) {
            isShowOutputAlert = true;
            isShowInputAllowence = false;
            isShowOutputAllowence = false;
        } else {
            if (Number(params.outputVal) > Number(params.token2allowance) && !isShowInputAlert) {
                isShowOutputAllowence = true;
            } else {
                isShowOutputAllowence = false;
            }
        }

        if (Number(total) > Number(params.inputOrg)) {
            isShowInputAlert = true;
            isShowInputAllowence = false;
            isShowOutputAllowence = false;
        } else {
            if (Number(total) > Number(params.token1allowance) && !isShowInputAlert) {
                isShowInputAllowence = true;
            } else {
                isShowInputAllowence = false;
            }
        }

        if (!isShowInputAllowence && !isShowOutputAllowence && Number(params.outputVal) && total) {
            isShowAddLiq = true;
            isAddLiqBtn = true;
        }

        var res = {
            outputAmt: params.outputVal,
            isShowInputAlert: isShowInputAlert,
            isShowOutputAlert: isShowOutputAlert,
            isShowInputAllowence: isShowInputAllowence,
            isShowOutputAllowence: isShowOutputAllowence,
            isShowAddLiq: isShowAddLiq,
            isAddLiqBtn: isAddLiqBtn,
            inputAmt: total
        };

        return Promise.resolve(res);
    }

    async calLiquidityData(params) {
        var res = {
            token0Out: 0,
            token1Out: 0
        };

        res.token0Out = (params.orgData.reserveA * params.liqAmt) / params.orgData.totalSupply;
        res.token1Out = (params.orgData.reserveB * params.liqAmt) / params.orgData.totalSupply;

        return Promise.resolve(res);
    }

    async calLiquidityMax(params) {
        var res = {
            token0Out: 0,
            token1Out: 0
        };

        res.token0Out = (params.orgData.reserveA * params.liqAmt) / params.orgData.totalSupply;
        res.token1Out = (params.orgData.reserveB * params.liqAmt) / params.orgData.totalSupply;

        return Promise.resolve(res);
    }
}

export default ExchangeService;
