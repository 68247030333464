import React from 'react';
import Parse from 'html-react-parser';

import BaseService from "../../services/common/BaseService";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Newsletter extends React.Component {
    constructor(props) {
        super(props);

        this.service = new BaseService();

        this.state = {
            email: "",
            emailErr: ""
        };

        this.subscriberEvt = this.subscriberEvt.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
    }

    componentDidMount() {
    }

    subscriberEvt = () => {
        var params = {
            email: this.state.email
        };

        this.service.subscriber(params).then((res) => {
            if (res.success) {
                toast.success(res.message);
            } else {
                this.setState({emailErr: res.message.email[0]});
            }
        });
    }

    changeEmail = (event) => {
        let email = event.target.value;

        this.setState({email: email, emailErr: ''});
    }

    render() {
        return (
            <>
                <div className="subscibe">
                    <h3 className="h3">Newsletter</h3>
                    <div className="subscibefrm">
                        <div className="form-group">
                            <div className="input-group">
                                <input type="email" className="form-control" placeholder="Email Address" value={this.state.email} onChange={this.changeEmail } />
                                <span className="input-group-text"><button className="btn sitebtn ashbtn" onClick={ this.subscriberEvt }>Subscribe</button></span>
                            </div>
                        </div>

                        { (this.state.emailErr.length > 0) && 
                            <span className='text-danger'>{this.state.emailErr}</span>
                        }
                    </div>
                </div>

                <ToastContainer autoClose={5000} />
            </>
        );
    }
}

export default Newsletter;