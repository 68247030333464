import React from "react";
import * as $ from 'jquery'; 
import {Link} from 'react-router-dom';
import eth from './../../assets/images/color/eth.svg';
import shib from './../../assets/images/color/shib.svg';
import apy from './../../assets/images/apy.svg';
import downarrow from './../../assets/images/downarrow.svg';
import arrow from './../../assets/images/arrow.svg';
import lock from './../../assets/images/lock.svg';

import nodata from './../../assets/images/nodata.svg';

const Pools = () => {
    function stakedtabfirst() 
	{
		$(".tab-pane .tab-content>div:first-child").addClass("show active");
		$(".tab-pane .tab-content>div:nth-child(2)").removeClass("show active");
    	$(".tab-pane .tab-content>div:last-child").removeClass("show active");
	}
	function stakedtabmiddle() 
	{
		$(".tab-pane .tab-content>div:first-child").removeClass("show active");
		$(".tab-pane .tab-content>div:nth-child(2)").addClass("show active");
		$(".tab-pane .tab-content>div:last-child").removeClass("show active");
	}
	function stakedtablast() 
	{
		$(".tab-pane .tab-content>div:first-child").removeClass("show active");
		$(".tab-pane .tab-content>div:nth-child(2)").removeClass("show active");
    	$(".tab-pane .tab-content>div:last-child").addClass("show active");
	}
  return (
  <div>
	<section className="topbanner panelcontentbox">
		<div className="container sitecontainer">
		<ul className="nav nav-tabs tabbanner innerpagetab">
			<li className="nav-item">
                <Link to="/farms" className="nav-link">Farms</Link>
			</li>
			<li className="nav-item">
                <Link to="/pools" className="nav-link active">Pools</Link>
			</li>										
		</ul>
		</div>
	</section>
		<article className="gridparentbox exchangegridbox">
			<section className="innerpagecontent stackedbanner">
				<div className="container sitecontainer">
					<div className="topcontentbox">
						<h3 className="heading-title pb-2">Just stake some tokens to earn</h3>
					</div>
				</div>
			</section>
			<div className="container sitecontainer stakebannerpage">
				<div className="panelcontentbox stakedtab">
					<div className="d-flex">
						
					<div className="innerpagetab historytab">						
							<ul className="nav nav-tabs tabbanner" role="tablist">								
								<li className="nav-item"><a onClick={stakedtabfirst} className="nav-link active" data-bs-toggle="tab" href="#pending" dat-bs-target="pending">Live</a></li>
								<li className="nav-item"><a onClick={stakedtabmiddle} className="nav-link" data-bs-toggle="tab" href="#completed" dat-bs-target="completed">Finished</a></li>
								<li className="nav-item"><a onClick={stakedtablast} className="nav-link" data-bs-toggle="tab" href="#locked" dat-bs-target="locked">Staked</a></li>
							</ul>
					</div>
					<div className="cardlist">
							<ul className="nav nav-tabs" role="tablist">
								<li className="nav-item"><a className="nav-link active" data-bs-toggle="tab" href="#card" dat-bs-target="card"><i className="fa fa-bars" aria-hidden="true"></i><span>Card</span></a></li>
								<li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#list" dat-bs-target="card"><i className="fa fa-list" aria-hidden="true"></i><span>List</span></a></li>
							</ul>
						</div>
				</div>
					<div className="tabrightbox">
						<div className="supportsearch">
							<form className="siteformbox">
								<div className="form-group">
									<div className="input-group">
										<div className="input-group-append">
											<button type="submit" name="" className="input-group-text"><i className="fa fa-search"></i></button>
										</div>
										<input type="text" className="form-control" placeholder="Search Pools" /> </div>
								</div>
							</form>
						</div>
						
					</div>
				</div>
				<div className="tab-content">
				<div className="tab-pane fade in show active" id="card" role="tabpanel">
				<div className="tab-content">
					<div className="tab-pane fade in show active" id="pending" role="tabpanel">
						<div className="row stackpage">
							<div className="col-12 text-center">
								<div className="panelcontentbox">
									<div className="table-responsive" data-simplebar>
										<table className="table sitetable">
											<tbody>
												<tr className="nodata">
													<td className="text-center">
													<img src={nodata} className="no-record" />
													<span>No records found </span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="tab-pane fade in" id="completed" role="tabpanel">
						<div className="row stackpage lowopacity">
							<div className="col-12 text-center">
								<div className="panelcontentbox">
									<div className="table-responsive" data-simplebar>
										<table className="table sitetable">
											<tbody>
												<tr className="nodata">
													<td className="text-center">
													<img src={nodata} className="no-record" />
													<span>No records found </span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="tab-pane fade in" id="locked" role="tabpanel">
						<div className="row stackpage">
							<div className="col-12 text-center">
								<div className="panelcontentbox">
									<div className="table-responsive" data-simplebar>
										<table className="table sitetable">
											<tbody>
												<tr className="nodata">
													<td className="text-center">
													<img src={nodata} className="no-record" />
													<span>No records found </span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="tab-pane fade in" id="list" role="tabpanel">
			<div className="tab-content">
					<div className="tab-pane fade in show active" id="pending" role="tabpanel">
						<div className="row stackpage stackcardbox">
							<div className="col-lg-3 col-md-4 col-sm-6 col-12">
								<div className="panelcontentbox">
									<div className="table-responsive" data-simplebar>
										<table className="table sitetable">
											<tbody>
												<tr className="nodata">
													<td className="text-center">
													<img src={nodata} className="no-record" />
													<span>No records found </span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="tab-pane fade in" id="completed" role="tabpanel">
						<div className="row stackpage stackcardbox lowopacity">
							<div className="col-lg-3 col-md-4 col-sm-6 col-12">
								<div className="panelcontentbox">
									<div className="table-responsive" data-simplebar>
										<table className="table sitetable">
											<tbody>
												<tr className="nodata">
													<td className="text-center">
													<img src={nodata} className="no-record" />
													<span>No records found </span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="tab-pane fade in" id="locked" role="tabpanel">
						<div className="row stackpage stackcardbox">
							<div className="col-lg-3 col-md-4 col-sm-6 col-12">
								<div className="panelcontentbox">
									<div className="table-responsive" data-simplebar>
										<table className="table sitetable">
											<tbody>
												<tr className="nodata">
													<td className="text-center">
													<img src={nodata} className="no-record" />
													<span>No records found </span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</div>
			<div className="modal fade modalbgt" id="stakelp">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">Staked ETH Tokens</h4>
							<button type="button" className="close" data-bs-dismiss="modal">&times;</button>
						</div>
						<div className="modal-body">
							<form className="siteformbox">
								<div className="bluedarkbox">
									<table className="table sitetable mb-0 stakelpmodal">
										<tbody>
											<tr>
												<td>Stake</td>
												<td className="text-end">Balance : 4.6921 ETH </td>
											</tr>
											<tr>
												<td>4.6921456135494561</td>
												<td className="text-end"> <span className="d-flex pull-right">
                                    <span>
                                        <ul className="settinglimit">
                                            <li><a>Max</a></li>
                                        </ul>
                                    </span> <span>USDT-ETH</span></span>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className="text-center mt-3 stakebtnrow d-flex"> <a href="#" className="btn border-btn me-2">Cancel</a> <a href="#" className="btn sitebtn">Confirm</a> </div>
							
							</form>
						</div>
					</div>
				</div>
			</div>
			<div className="modal fade modalbgt" id="unstake">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">Unstaked</h4>
							<button type="button" className="close" data-bs-dismiss="modal">&times;</button>
						</div>
						<div className="modal-body">
							<form className="siteformbox text-center"> <img src={lock}className="lockicon" />
							<p className="text-center">You agree to Trade Exchange Terms of Service and acknowledge that you have read and understand the Trade Exchange Protocol Disclaimer. </p>

								<div className="text-center mt-3 stakebtnrow d-flex"> <a href="#" className="btn border-btn me-2">Cancel</a> <a href="#" className="btn sitebtn">Confirm</a> </div>
							</form>
						</div>
					</div>
				</div>
			</div>
			</article>
</div>
  );
};
  
export default Pools;